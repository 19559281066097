import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { enqueueSnackbar } from "notistack";

import {
  Stepper,
  Step,
  StepLabel,
  Typography,
  Tooltip,
  Button,
  ClickAwayListener,
  Card,
} from "@mui/material";

import "./OrderTracker.css";
import OrderTimer from "./OrderTimer";
import StatusGifs from "./StatusGifs";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { FiBell, FiBellOff } from "react-icons/fi";
import { Refresh } from "@mui/icons-material";
import {
  setTrackerActiveStep,
  setTrackerDelayed,
  setTrackerDuration,
  setTrackerRejected,
} from "../../../ToolKit/Slices/OrderSlice";
import { setNotificationPermission } from "../../../ToolKit/Slices/UserSlice";

const OrderTracker = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { order, settings } = props;
  const { activeStep, rejected, delayed, reason, duration } = props.trackers;

  const [open, setOpen] = React.useState(false);
  const notiPermission = useSelector((state) => state.user.notificationPermission);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const showSnack = (message, type, persist) => {
    enqueueSnackbar(message, {
      variant: type,
      persist: persist,
    });
  };

  const showActionSnack = () => {
    const action = (key) => (
      <React.Fragment>
        <Button
          variant="outlined"
          size="small"
          onClick={() => {
            props.closeSnackbar(key);
          }}
        >
          {t("tracker.HowToAllow")}
        </Button>
        <Button
          variant="text"
          size="small"
          onClick={() => {
            props.closeSnackbar(key);
          }}
          style={{ margin: "5px" }}
        >
          {t("tracker.Close")}
        </Button>
      </React.Fragment>
    );
    let message = t("tracker.You have blocked our request for notification");
    enqueueSnackbar(message, {
      variant: "warning",
      persist: true,
      action,
    });
  };
  const steps = [
    t("tracker.Reviewing"),
    t("tracker.Preparing"),
    order.orderType === "pickup" || order.orderType === "kiosk"
      ? t("tracker.ready")
      : t("tracker.On the way"),
  ];
  const stepContent = [
    t("tracker.Please wait"),
    t("tracker.Order Accepted Now preparing"),
    t("tracker.Your order is on the way"),
  ];

  const reloadTrackerStatus = () => {
    axios
      .get(`${import.meta.env.VITE_API_URL}/order/get_status`, {
        params: { orderId: order.id },
      })
      .then((res) => {
        const status = res.data;
        if (status.status === null) dispatch(setTrackerActiveStep({ count: 0, id: order.id }));
        if (status.status === "preparing") {
          dispatch(setTrackerActiveStep({ count: 1, id: order.id }));
          dispatch(
            setTrackerDuration({ duration: parseInt(status.duration[1], 10), id: order.id })
          );
        }
        if (status.status === "enroute" || status.status === "ready")
          dispatch(setTrackerActiveStep({ count: 2, id: order.id }));
        if (status.status === "rejected") {
          dispatch(setTrackerRejected(true, order.id));
          // dispatch(actions.setTrackerReason(status.reason));
        }
        if (status.status === "delayed") dispatch(setTrackerDelayed(true, order.id));
        if (status.status === "update") {
          dispatch(
            setTrackerDuration({ duration: parseInt(status.duration[1], 10), id: order.id })
          );
        }
      });
  };

  useEffect(() => {
    reloadTrackerStatus();
    return () => {};
  }, []);

  const checkNotificationPromise = () => {
    try {
      Notification?.requestPermission().then();
    } catch (e) {
      return false;
    }

    return true;
  };

  const askNotificationPermission = () => {
    setOpen(false);
    // function to actually ask the permissions
    function handlePermission(permission) {
      // Whatever the user answers, we make sure Chrome stores the information
      if (!("permission" in Notification)) {
        Notification.permission = permission;
      }

      // set the button to shown or hidden, depending on what the user answers
      dispatch(setNotificationPermission({ notify: Notification?.permission }));
      if (Notification?.permission === "denied") {
        showActionSnack();
      } else {
        showSnack(t("You will get notification when order status changes"), "success", false);
      }
    }

    // Let's check if the browser supports notifications
    if (!window.Notification) {
      console.log("This browser does not support notifications.");
    } else {
      if (checkNotificationPromise()) {
        Notification?.requestPermission().then((permission) => {
          handlePermission(permission);
        });
      } else {
        Notification?.requestPermission(function (permission) {
          handlePermission(permission);
        });
      }
    }
  };

  useEffect(() => {
    let timeout = null;
    if (notiPermission !== "granted") {
      timeout = setTimeout(() => {
        setOpen(true);
      }, 3000);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [notiPermission]);

  const themeMode = useSelector((state) => state.user.theme);

  return (
    <Card
      style={{
        border: "1px solid #ccc",
        borderRadius: "5px",
        width: "100%",
        padding: 10,
        backgroundColor: themeMode === "dark" ? "#000000" : "white",
        background: themeMode === "dark" ? "#000000" : "white",
        // color: "black",
      }}
    >
      {rejected ? (
        <div style={{ textAlign: "center" }}>
          <p>{t("tracker.Order rejected by shopkeeper")}</p>
          <p>
            {t("tracker.Reason")}: {reason}
          </p>
        </div>
      ) : delayed && activeStep === 0 ? (
        <div style={{ textAlign: "center" }}>
          <p>{t("tracker.Order accepted by shopkeeper")}</p>
          <p>
            {order.orderType === "delivery"
              ? t("orderSummary.orderWillBeDelivereAt") + order.delayedTime.slice(0, 5)
              : t("orderSummary.orderWillBeReadytAt") + order.delayedTime.slice(0, 5)}
          </p>
        </div>
      ) : (
        <React.Fragment>
          <div
            style={{
              padding: "0 5px 0 5px",
              textAlign: "right",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Button
              size="medium"
              onClick={() => reloadTrackerStatus()}
              variant="text"
              color="primary"
            >
              <Refresh />
            </Button>
            <ClickAwayListener onClickAway={handleTooltipClose}>
              <Tooltip
                PopperProps={{
                  disablePortal: true,
                }}
                onClose={handleTooltipClose}
                open={open}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                title={t("tracker.Enable to get live notifications for your order updates")}
                arrow
                placement="top"
              >
                {notiPermission === "granted" ? (
                  <Button
                    size="large"
                    // onClick={() => askNotificationPermission()}
                    variant="text"
                    color="primary"
                  >
                    <FiBell />
                  </Button>
                ) : (
                  <Button
                    size="large"
                    // onClick={() => askNotificationPermission()}
                    variant="text"
                    color="inherit"
                  >
                    <FiBellOff />
                  </Button>
                )}
              </Tooltip>
            </ClickAwayListener>
          </div>
          {duration ? (
            <p style={{ textAlign: "center" }}>
              <OrderTimer duration={duration} />
              <br />
              <span style={{ textAlign: "center", fontSize: 10 }}>
                {t("tracker.expectedDeliveryTime")}
              </span>
            </p>
          ) : null}
          {order?.orderType === "kiosk" && (
            <h4 className="text-center text-[20px] font-bold">
              {t("cart.yourOrder")} # {order?.serial.slice(0, 4).toUpperCase()}
            </h4>
          )}
          <h4 className="StatusHeading">{t("tracker.Order Status")}</h4>

          <React.Fragment>
            <StatusGifs activeStep={activeStep} orderType={order.orderType} />
            <Stepper activeStep={activeStep} alternativeLabel style={{ padding: 10 }}>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <div>
              {activeStep === steps.length ? (
                <div>
                  <Typography
                  // className={classes.instructions}
                  >
                    {t("tracker.Enjoy your food")}
                  </Typography>
                </div>
              ) : (
                <div>
                  <Typography
                    sx={{
                      textAlign: "center",
                    }}
                    // className={classes.instructions}
                  >
                    {stepContent[activeStep]}
                    <br />
                    {activeStep === 1 ? (
                      <span
                        style={{
                          textAlign: "center",
                          marginTop: 10,
                          fontSize: 14,
                        }}
                      >
                        {t("orderSummary.thankyouForOrder")}
                        <br />
                        {settings.sendEmailtoCustomer
                          ? t("orderSummary.youWillReceiveEmail")
                          : null}
                      </span>
                    ) : null}
                  </Typography>
                </div>
              )}
            </div>
          </React.Fragment>
        </React.Fragment>
      )}
    </Card>
  );
};

export default OrderTracker;
