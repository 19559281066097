// import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";

import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setOrderTime, updatePaymentStatus } from "../../ToolKit/Slices/OrderSlice";
import { useEffect } from "react";
import { useCallback } from "react";
import { registerWithOrder } from "../../ToolKit/Slices/UserSlice";
import axios from "axios";
import { setIntervals } from "../../ToolKit/Slices/ShopSlice";

const PaypalSDK = (props) => {
  const ppConfig = useSelector((state) => state.shop.paypal);
  const currOrder = useSelector((state) => state.order.currOrder);
  const currUser = useSelector((state) => state.user.currUser);
  const pickupIntervals = useSelector((state) => state.shop?.pickupTimeIntervals);
  const deliveryIntervals = useSelector((state) => state.shop?.deliveryTimeIntervals);
  const separateTimings = useSelector((state) => state.shop?.settings?.separateTimings);
  const orderType = useSelector((state) => state.cart.orderType);
  const isDeliveryAvailable = useSelector((state) => state.shop.isDeliveryAvailable);
  const isPickupAvailable = useSelector((state) => state.shop.isPickupAvailable);

  const clientId = ppConfig.sandBox ? "sb" : ppConfig.clientId;
  const merchantId = ppConfig.merchantId;

  useEffect(() => {
    // console.log("this use effect is basically for paypal order update: ", currOrder);
  }, [currOrder]);

  const checkDelayedOrder = () => {
    if (orderType !== "" && separateTimings) {
      if (orderType === "delivery") {
        dispatch(setIntervals(deliveryIntervals));
        if (!isDeliveryAvailable?.status && isDeliveryAvailable?.message === "less") {
          dispatch(setOrderTime({ time: "Choose Time", delayed: true }));
        } else {
          dispatch(setOrderTime({ time: "As soon as possible", delayed: false }));
        }
      } else if (orderType === "pickup") {
        dispatch(setIntervals(pickupIntervals));
        if (!isPickupAvailable?.status && isPickupAvailable?.message === "less") {
          dispatch(setOrderTime({ time: "Choose Time", delayed: true }));
        } else {
          dispatch(setOrderTime({ time: "As soon as possible", delayed: false }));
        }
      }
    }
  };
  const currency = ppConfig.currency;

  const dispatch = useDispatch();

  let total = parseFloat(props.grandTotal, 10);
  const sdkOptions =
    merchantId !== ""
      ? {
          clientId: clientId,
          currency: currency,
          locale: "de_DE",

          disableFunding: ppConfig.disableFunding,
        }
      : {
          clientId: clientId,
          currency: currency,
          locale: "de_DE",
          disableFunding: ppConfig.disableFunding,
        };

  const onSuccess = useCallback(
    async (data, actions, currOrder) => {
      try {
        const response = await axios.post(
          `${import.meta.env.VITE_API_URL}/paypal/orders/${data.orderID}/capture`,
          {
            isSandbox: ppConfig.sandBox,
          }
        );

        const orderData = response.data;
        // Three cases to handle:
        //   (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
        //   (2) Other non-recoverable errors -> Show a failure message
        //   (3) Successful transaction -> Show confirmation or thank you message

        const errorDetail = orderData?.details?.[0];

        if (errorDetail?.issue === "INSTRUMENT_DECLINED") {
          // (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
          // recoverable state, per https://developer.paypal.com/docs/checkout/standard/customize/handle-funding-failures/
          return actions.restart();
        } else if (errorDetail) {
          // (2) Other non-recoverable errors -> Show a failure message
          throw new Error(`${errorDetail.description} (${orderData.debug_id})`);
        } else if (!orderData.purchase_units) {
          throw new Error(JSON.stringify(orderData));
        } else {
          // (3) Successful transaction -> Show confirmation or thank you message
          // Or go to another URL:  actions.redirect('thank_you.html');
          const transaction =
            orderData?.purchase_units?.[0]?.payments?.captures?.[0] ||
            orderData?.purchase_units?.[0]?.payments?.authorizations?.[0];
          // console.log(
          //   `Transaction ${transaction.status}: ${transaction.id}<br><br>See console for all available details`
          // );
          // console.log("Capture result", orderData, JSON.stringify(orderData, null, 2));
          const status = "payed";
          dispatch(
            updatePaymentStatus({
              status,
              payment: {
                ...data,
                payer: {
                  ...orderData.payer,
                  account_status: orderData?.payment_source?.paypal?.account_status,
                },
                payment_status: orderData?.status,
              },
              orderId: currOrder.id,
              currUser,
            })
          ).then(() => {
            if (props.userAction === "register") {
              const data = { ...currOrder.userData, orders: currOrder.id };

              dispatch(registerWithOrder(data));
            }
          });
        }
      } catch (error) {
        console.error(error);
        console.error(`Sorry, your transaction could not be processed...<br><br>${error}`);
      }
    },
    [currOrder]
  );

  const createOrder = async () => {
    try {
      const response = await axios.post(`${import.meta.env.VITE_API_URL}/paypal/create-order`, {
        cart: [
          {
            id: "YOUR_PRODUCT_ID",
            quantity: "YOUR_PRODUCT_QUANTITY",
          },
        ],
        isSandbox: ppConfig.sandBox,
        amount_total: total,
      });

      console.log("response", response);

      const orderData = response.data;

      if (orderData.id) {
        return orderData.id;
      } else {
        const errorDetail = orderData?.details?.[0];
        const errorMessage = errorDetail
          ? `${errorDetail.issue} ${errorDetail.description} (${orderData.debug_id})`
          : JSON.stringify(orderData);

        throw new Error(errorMessage);
      }
    } catch (error) {
      console.error(error);
      console.error(`Could not initiate PayPal Checkout...<br><br>${error}`);
    }
  };

  const onCancel = (data) => {
    console.log("The payment was cancelled!");
    checkDelayedOrder();
    props.onCancel(data);
  };

  const onError = (err) => {
    console.log("Paypal Error!", err);
    checkDelayedOrder();
    // Because the Paypal's main script is loaded asynchronously from "https://www.paypalobjects.com/api/checkout.js"
    // => sometimes it may take about 0.5 second for everything to get set, or for the button to appear
    props.onError(err);
  };

  //   { clientId: "test", components: "buttons", currency: "USD" }
  return (
    <PayPalScriptProvider
      options={{
        ...sdkOptions,
        // components: "buttons,funding-eligibility,applepay",
        // enableFunding: "applepay",
      }}
    >
      <div
        className="flex justify-center items-center"
        style={{
          opacity: !props.ppButtonLoading ? 1 : 0,
        }}
      >
        <PayPalButtons
          createOrder={createOrder}
          onInit={props.afterButtonReady}
          onApprove={(data, actions) => onSuccess(data, actions, currOrder)}
          onCancel={onCancel}
          onError={onError}
          style={{
            color: "gold",
            layout: "vertical",
            // height: 48,
            tagline: false,
            shape: "pill",
          }}
        />
      </div>
    </PayPalScriptProvider>
  );
};

export default PaypalSDK;
