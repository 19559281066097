import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { GiBarbecue } from "react-icons/gi";
import { MdDoneAll } from "react-icons/md";

import { CancelOutlined, LocalMallSharp } from "@mui/icons-material";
import { Paper, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import "./UserOrders.css";
import { socket } from "../../../App";
import PulseLoader from "react-spinners/PulseLoader";
import { AiOutlineFileSearch } from "react-icons/ai";
import { AiOutlineClockCircle } from "react-icons/ai";
import { FaShippingFast } from "react-icons/fa";
import { PropagateLoader } from "react-spinners";
import { isMobile } from "react-device-detect";
import { setCurrOrder } from "../../../ToolKit/Slices/OrderSlice";

import { fetchUserOrders } from "../../../ToolKit/Slices/UserSlice";
import OrderItemsAccordion from "./OrderItemsAccordion";

export const UserOrders = (props) => {
  const { t } = useTranslation();
  const { setShowSummary, setCurrOrderLocal } = props;
  const currUser = useSelector((state) => state.user.currUser);
  const deliveryAreas = useSelector((state) => state.shop.deliveryAreas);

  const dispatch = useDispatch();
  const regOrders = useSelector((state) => state.user.orders);
  const localOrders = useSelector((state) => state.order.localOrders);

  const ordersContainerVisible = useSelector((state) => state.order.ordersContainerVisible);
  const serverTimeDateEpoch = useSelector((state) => state.shop.serverTimeDateEpoch);

  const now = serverTimeDateEpoch;
  let orders = [];
  if (currUser && (Array.isArray(regOrders) ? regOrders[0] : false)) {
    orders = regOrders.filter((o) => o.paymentStatus === null || o.paymentStatus === "payed");
  } else {
    orders = localOrders;
  }

  if (isMobile) {
    window.onbeforeunload = function () {
      return t("warning");
    };
  }

  const loading = useSelector((s) => s.user.userOrderLoading);
  const priceFormat = window.$priceFormater;

  const options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
    timeZone: "Europe/Berlin",
  };

  useEffect(() => {
    let isCancelled = false;
    socket.on("statusChange", (status) => {
      setTimeout(() => {
        if (!isCancelled && ordersContainerVisible) {
          dispatch(fetchUserOrders({ currUser, localOrders }));
        }
      }, 1000);
    });

    dispatch(fetchUserOrders({ currUser, localOrders }));
    return () => {
      isCancelled = true;
    };
  }, []);

  // useEffect(() => {
  //   if (props.currOrder) {
  //     socket.emit("join", props.currOrder.id);
  //   }
  //   return () => {};
  // }, []);

  const openOrderHandler = (order) => {
    if (order.status !== "completed") {
      dispatch(setCurrOrder({ order }));
    }
    setCurrOrderLocal(order);
    setShowSummary(true);
  };

  const durationCalc = (duration) => {
    return now - parseInt(duration, 10) < 43200000;
  };

  const orderColor = (status) => {
    if (status === "completed") return "#b5b5b5";
    if (status === "preparing") return "#ff984e";
    if (status === "enroute") return "#68e0a3";
    if (status === "delayed") return "#68baff";
    if (status === "rejected") return "#ff7979";
    if (status === null) return "#ffc747";
  };
  const orderStatus = (status, oType) => {
    if (status === "completed") return null;
    if (status === "preparing") return t("tracker.Preparing");
    if (status === "enroute" && oType === "delivery") {
      return t("tracker.On the way");
    }
    if (status === "enroute" && oType === "pickup") {
      return t("tracker.ready");
    }

    if (status === "delayed") return t("tracker.Delayed");
    if (status === "rejected") return t("tracker.Rejected");
    if (status === null) return t("tracker.Reviewing");
  };

  const theme = useTheme();
  return (
    <div>
      <div
        style={{
          alignSelf: "center",
          textAlign: "center",
          padding: 5,
          display: loading ? "block" : "none",
        }}
      >
        <PulseLoader color={theme.palette.primary.main} />
        {/* <p>{t("orderSummary.pleaseWait")}</p> */}
      </div>
      {orders.length > 0 ? (
        <div className="HistoryOrders">
          {[...orders].reverse().map((order, index) => {
            let status = order.status;
            if (now - new Date(order.orderTimestamp).getTime() > 64800000) {
              status = "completed";
            }
            return order ? (
              <>
                <Paper
                  elevation={status === "completed" || status === "rejected" ? 0 : 2}
                  style={{ borderLeft: `5px solid ${orderColor(status)}` }}
                  key={index}
                  className="OrderPaper"
                >
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      openOrderHandler(order);
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginBottom: "5px",
                        fontWeight: "bold",
                        color: status === "completed" ? "gray" : "unset",
                      }}
                    >
                      <div>
                        {order.items ? order.items.length : null} Produkte
                        {/* {order.items.length > 1 ? "s" : null} */}
                      </div>
                      {status === "preparing" || status === "enroute" || status === null ? (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <div
                            style={{
                              fontSize: "12px",
                              fontWeight: "normal",
                              paddingBottom: "5px",
                            }}
                          >
                            {orderStatus(status, order.orderType)}
                          </div>
                          <div>
                            <PropagateLoader size={4} color={orderColor(status)} />
                            {/* <BarLoader
                          height={5}
                          width={75}
                          color={orderColor(status)}
                          css={override}
                        /> */}
                          </div>
                        </div>
                      ) : null}

                      <div>{priceFormat.format(order.grandTotal)}</div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        fontSize: "12px",
                        color: "grey",
                      }}
                    >
                      <div>
                        #{" "}
                        {order.orderType === "kiosk" ? (
                          <span className="font-bold text-lg">
                            {order.serial.slice(0, 4).toUpperCase()}
                          </span>
                        ) : (
                          order.serial
                        )}
                        <br />
                        {new Date(order.orderTimestamp).toLocaleDateString("de-EU", options)}
                      </div>
                      {(status === "preparing" || status === "enroute") &&
                      order.duration !== null ? (
                        durationCalc(order.duration[1]) ? (
                          <div style={{ textAlign: "center", padding: 10 }}>
                            <i style={{ fontSize: 12 }}>ETA:</i>
                            {"  "}
                            <b>
                              {new Date(parseInt(order.duration[1], 10))
                                .toLocaleTimeString("de-DE")
                                .slice(0, 5)}
                            </b>
                          </div>
                        ) : null
                      ) : null}
                      <div></div>
                      <div
                        style={{
                          fontSize: "30px",
                          color: orderColor(status),
                          paddingRight: "5px",
                        }}
                      >
                        {status === "preparing" ? (
                          <GiBarbecue size="28px" />
                        ) : status === "delayed" ? (
                          <AiOutlineClockCircle size="28px" />
                        ) : status === "enroute" && order.orderType === "delivery" ? (
                          <FaShippingFast size="28px" />
                        ) : status === "enroute" && order.orderType === "pickup" ? (
                          <LocalMallSharp size="28px" />
                        ) : status === "completed" ? (
                          <MdDoneAll size="28px" />
                        ) : status === "rejected" ? (
                          <CancelOutlined fontSize="default" />
                        ) : status === null ? (
                          <AiOutlineFileSearch size="28px" />
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <OrderItemsAccordion order={order} />
                </Paper>
              </>
            ) : null;
          })}
        </div>
      ) : (
        <p style={{ textAlign: "center" }}>{t("userOrders.noRecentOrder")}</p>
      )}
    </div>
  );
};

export default UserOrders;
