import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { enqueueSnackbar } from "notistack";

import {
  Button,
  Paper,
  Dialog,
  Chip,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  Typography,
  useTheme,
  Box,
} from "@mui/material";

// import MaterialTable from "material-table";
import useWindowDimensions from "../ScreenSize/ScreenSize";
import ShopMap from "../../Shop/ShopMap/ShopMap";
import "./AreaSelection.css";

import { FaClock } from "react-icons/fa";

import { useTranslation } from "react-i18next";

import styled, { keyframes } from "styled-components";
import { slideInUp } from "react-animations";
import { selectAddressLocal } from "../../../ToolKit/Slices/UserSlice";
import { selectArea, selectAreaToggle, setOrderTime } from "../../../ToolKit/Slices/OrderSlice";
import {
  addToCart,
  increaseQuantity,
  resetCart,
  setDeliveryCharge,
  setOrderType,
} from "../../../ToolKit/Slices/CartSlice";
import { useEffect } from "react";
import { sortAreas } from "../../../ToolKit/Slices/utils";
import { resetVoucher } from "../../../ToolKit/Slices/ShopSlice";
import ShowTime from "../../Shop/ShopData/TimingsTable/ShowTime";
import IntervalSelector from "../IntervalSelector/IntervalSelector";
import ShopOpeningTime from "../../CategoryNav/ShopOpeningTime";
import IntervalAccordion from "../IntervalSelector/IntervalAccordion";
import ShowTimeNew from "../../Shop/ShopData/TimingsTable/ShowTimeNew";
import UserData from "../../Users/UserProfile/UserData";

const slideAnimation = keyframes`${slideInUp}`;

const SlideDiv = styled.div`
  animation: 0.3s ${slideAnimation};
`;

const AreaSelection = (props) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  // states
  const pickupIntervals = useSelector((state) => state.shop?.pickupTimeIntervals);
  const deliveryIntervals = useSelector((state) => state.shop?.deliveryTimeIntervals);
  const areas = useSelector((state) => state.shop.deliveryAreas);
  const cartItems = useSelector((state) => state.cart.cartItems);
  const cartCount = useSelector((state) => state.cart.cartItemCount);
  const selectedArea = useSelector((state) => state.order.selectedArea);
  const orderTypeCart = useSelector((state) => state.cart.orderType);
  const discounts = useSelector((state) => state.shop.settings?.discounts);
  const shopData = useSelector((state) => state.shop.shopData);
  const deliveryON = useSelector((state) => state.shop.deliveryON);
  const pickupON = useSelector((state) => state.shop.pickupON);
  const addresses = useSelector((state) => state.user.addresses);
  const currUser = useSelector((state) => state.user.currUser);
  const userAction = useSelector((state) => state.user.userAction);
  const userAddresses = useSelector((state) => state.user.addresses);
  const isDeliveryAvailable = useSelector((state) => state.shop.isDeliveryAvailable);
  const isPickupAvailable = useSelector((state) => state.shop.isPickupAvailable);
  const todayTiming = useSelector((state) => state.shop.todayTiming);
  const separateTimings = useSelector((state) => state.shop.settings?.separateTimings);
  const isShopCurrentlyOpen = useSelector((state) => state.shop.isShopCurrentlyOpen);

  const [userAddress, setUserAddress1] = useState(
    currUser ? userAddresses.filter((addr) => addr.selected === true)[0] : null
  );
  const addingNewAddress = useSelector((state) => state.user.addingNewAddress);
  const showAreaSelection = useSelector((state) => state.order.showAreaSelection);
  const sortingObj = useSelector((state) => state.shop.settings.deliveryAreaSorting);
  const settings = useSelector((state) => state.shop.settings);
  const defaultDeliveryTime = settings?.livePanelSettings?.defaultDeliveryTime;
  const defaultPickupTime = settings?.livePanelSettings?.defaultPickupTime;

  const isDeliveryDisable = useSelector((state) => state.shop.settings?.isDeliveryDisable);

  const { screenHeight } = useWindowDimensions();
  const [areaHook, setArea] = useState({});
  const [orderTypeLocal, setOrderTypeLocal] = useState(orderTypeCart);
  const [isItemSelected, setIsItemSelected] = useState(true);
  // const [filteredAreas, setFilteredAreas] = useState(areas);
  const [sortedDeliveryAreas, setSortedDeliveryAreas] = useState(null);
  const [chosenInterval, setChosenInterval] = useState(null);

  const [searchContent, setSearchContent] = useState("");

  const { t } = useTranslation();

  useEffect(() => {
    if (selectedArea && isItemSelected === true) {
      setIsItemSelected(selectedArea);
    }
  }, []);

  useEffect(() => {
    setArea((currUser && !addingNewAddress) || selectArea.id ? { area: "selected" } : "");
  }, [selectedArea]);

  useEffect(() => {
    if (areas.length !== 0) {
      const notDefaultAreas = areas.filter((f) => !f.isDefault);
      const newAreas = sortAreas(sortingObj, notDefaultAreas);
      setSortedDeliveryAreas(newAreas);
    }
  }, [areas]);

  useEffect(() => {
    if (searchContent?.length > 2) {
      const filteredArray = sortedDeliveryAreas.filter((f) => {
        // || f.postalCode.includes(searchContent)
        if (
          f.area.toLowerCase().trim().includes(searchContent.toLowerCase()) ||
          f.postalCode.toLowerCase().trim().includes(searchContent.toLowerCase())
        ) {
          return f;
        }
      });
      setSortedDeliveryAreas(filteredArray);
    } else {
      const notDefaultAreas = areas.filter((f) => !f.isDefault);
      const newAreas = sortAreas(sortingObj, notDefaultAreas);

      setSortedDeliveryAreas(newAreas);
    }
  }, [searchContent]);

  const showSnack = (message, type) => {
    enqueueSnackbar(message, {
      variant: type,
    });
  };

  const setUserAddress = () => {
    if (addresses[0].street && deliveryON) {
      const selectedAdd = addresses.find((a) => a.selected);
      const deliveryCharges = areas.find(
        (area) => area.id === selectedAdd.userAreaId
      ).deliveryCharges;
      dispatch(selectAddressLocal({ addrId: selectedAdd.addrId }));
      dispatch(selectArea(areas.find((area) => area.id === selectedAdd.userAreaId)));

      dispatch(setDeliveryCharge(deliveryCharges));
      dispatch(setOrderType({ orderTypeLocal, deliveryCharges }));
    }
    dispatch(selectAreaToggle(false));
  };

  const handleChange = (e) => {
    setSearchContent(e.target.value.trim());
  };

  const calculatePrice = (currItem, currToppings, orderType) => {
    let toppingAmount = 0;
    currToppings.forEach((el) => {
      toppingAmount += el.price;
    });
    let orignalPrice = currItem.price + toppingAmount;
    let discPrice = null;

    if (!currItem.isDiscount) {
      if (discounts?.deliveryDiscountActive && orderType === "delivery" && currItem.deliveryDisc) {
        discPrice = orignalPrice - orignalPrice * (discounts?.deliveryDiscount / 100);
      } else if (discounts?.pickupDiscountActive && orderType === "pickup" && currItem.pickupDisc) {
        discPrice = orignalPrice - orignalPrice * (discounts?.pickupDiscount / 100);
      } else {
        discPrice = null;
      }
    } else {
      if (orderType === "delivery") {
        if (currItem.discounts.deliveryType === "percentage") {
          discPrice = orignalPrice - (orignalPrice / 100) * currItem.discounts.delivery;
        } else {
          discPrice = orignalPrice - currItem.discounts.delivery;
        }
      } else {
        if (currItem.discounts.pickupType === "percentage") {
          discPrice = orignalPrice - (orignalPrice / 100) * currItem.discounts.pickup;
        } else {
          discPrice = orignalPrice - currItem.discounts.pickup;
        }
      }
    }

    return { discPrice, toppingAmount };
  };

  const changeOrderType = (type, deliveryCharges) => {
    dispatch(setOrderType({ orderTypeLocal: type, deliveryCharges: deliveryCharges }));
    if (cartCount > 0) {
      let cart = cartItems;
      dispatch(resetCart(true));
      dispatch(resetVoucher());
      cart.forEach((el) => {
        if (el.toppings?.length > 0) {
          let obj = calculatePrice(el, el.toppings, type);
          dispatch(
            addToCart({
              item: { ...el, cartId: el.cartId },
              toppings: {
                toppings: el.toppings,
                toppingAmount: obj.toppingAmount,
              },
              discPrice: obj.discPrice,
            })
          );

          for (let i = 1; i < el.quantity; i++) {
            dispatch(
              increaseQuantity({
                id: el.cartId,
                discount: obj.discPrice ? obj.discPrice : el.price + obj.toppingAmount,
                price: el.price + obj.toppingAmount,
              })
            );
          }
        } else {
          let obj = calculatePrice(el, [], type);
          dispatch(
            addToCart({
              item: { ...el, cartId: el.cartId, quantity: 1 },
              toppings: null,
              discPrice: obj.discPrice,
            })
          );
          for (let i = 1; i < el.quantity; i++) {
            dispatch(
              increaseQuantity({
                id: el.cartId,
                discount: obj.discPrice ? obj.discPrice : el.price + obj.toppingAmount,
                price: el.price + obj.toppingAmount,
              })
            );
          }
        }
      });
    }
  };

  const showPickupIntervalSelection = () => {
    if (isPickupAvailable?.status === false && isPickupAvailable?.message === "less") {
      return true;
    } else {
      return false;
    }
  };

  const showDeliveryIntervalSelection = () => {
    if (isDeliveryAvailable?.status === false && isDeliveryAvailable?.message === "less") {
      return true;
    } else {
      return false;
    }
  };

  const changeCustomerOrderType = (area, deliveryCharges, orderTypeLocal) => {
    console.log("changeCustomerOrderType");

    dispatch(selectArea(area));
    dispatch(setDeliveryCharge(deliveryCharges));
    changeOrderType(orderTypeLocal, deliveryCharges);
  };

  let actionBtn = (
    <>
      {/* {orderTypeLocal !== "delivery" || (currUser && !addingNewAddress) ? null : (
        <span>
          {areaHook ? (
            <div className="AreaChip">
              <Chip label={areaHook.area + " " + areaHook.postalCode} />
            </div>
          ) : selectedArea?.id ? (
            <div className="AreaChip">
              <Chip label={selectedArea?.postalCode + " " + selectedArea?.area} />
            </div>
          ) : (
            <div className="AreaChip">
              <Chip label={t("orderQueryDialog.noArea")} />
            </div>
          )}
        </span>
      )} */}
      <span style={{ paddingLeft: "10px" }}>
        <Button
          disabled={
            orderTypeLocal === "pickup"
              ? false
              : (orderTypeLocal !== "" && areaHook.area && userAction !== "register") ||
                selectedArea?.id
              ? false
              : (areaHook.area && userAction === "register") || selectedArea?.id
              ? false
              : true
          }
          variant="outlined"
          color="primary"
          onClick={
            orderTypeLocal === "delivery" || userAction === "register"
              ? currUser && !addingNewAddress
                ? () => setUserAddress()
                : () => {
                    if (areaHook === "" && selectedArea?.id) {
                      if (separateTimings) {
                        console.log("orderTypeLocal", orderTypeLocal);
                        console.log("chosenInterval", chosenInterval);
                        console.log("showPickupIntervalSelection()", showPickupIntervalSelection());
                        console.log("showPickupIntervalSelection()", showPickupIntervalSelection());
                        if (
                          chosenInterval === null &&
                          orderTypeLocal === "delivery" &&
                          showDeliveryIntervalSelection()
                        ) {
                          showSnack(t("Please Select A Time First"), "warning");
                        } else if (
                          chosenInterval === null &&
                          orderTypeLocal === "pickup" &&
                          showPickupIntervalSelection()
                        ) {
                          showSnack(t("Please Select A Time First"), "warning");
                        } else if (chosenInterval !== null) {
                          console.log("chosenInterval", chosenInterval);
                          dispatch(
                            setOrderTime({
                              time: chosenInterval,
                              delayed: true,
                            })
                          );

                          // dispatch(selectArea(selectedArea));
                          // dispatch(setDeliveryCharge(selectedArea?.deliveryCharges));
                          // changeOrderType(orderTypeLocal, selectedArea?.deliveryCharges);
                          changeCustomerOrderType(
                            selectedArea,
                            selectedArea?.deliveryCharges,
                            orderTypeLocal
                          );
                          dispatch(selectAreaToggle(false));
                        } else {
                          changeCustomerOrderType(
                            selectedArea,
                            selectedArea?.deliveryCharges,
                            orderTypeLocal
                          );
                          dispatch(selectAreaToggle(false));
                        }
                      } else {
                        // dispatch(selectArea(selectedArea));
                        // dispatch(setDeliveryCharge(selectedArea?.deliveryCharges));
                        // changeOrderType(orderTypeLocal, selectedArea?.deliveryCharges);
                        changeCustomerOrderType(
                          selectedArea,
                          selectedArea?.deliveryCharges,
                          orderTypeLocal
                        );

                        dispatch(selectAreaToggle(false));
                      }
                    } else {
                      if (separateTimings) {
                        if (
                          chosenInterval === null &&
                          orderTypeLocal === "delivery" &&
                          showDeliveryIntervalSelection()
                        ) {
                          showSnack(t("Please Select A Time First"), "warning");
                        } else if (
                          chosenInterval === null &&
                          orderTypeLocal === "pickup" &&
                          showPickupIntervalSelection()
                        ) {
                          showSnack(t("Please Select A Time First"), "warning");
                        } else if (chosenInterval !== null) {
                          console.log("chosenInterval", chosenInterval);
                          dispatch(
                            setOrderTime({
                              time: chosenInterval,
                              delayed: true,
                            })
                          );
                          changeCustomerOrderType(
                            areaHook,
                            areaHook.deliveryCharges,
                            orderTypeLocal
                          );

                          // dispatch(selectArea(areaHook));
                          // dispatch(setDeliveryCharge(areaHook.deliveryCharges));
                          // changeOrderType(orderTypeLocal, areaHook.deliveryCharges);

                          dispatch(selectAreaToggle(false));
                        } else {
                          changeCustomerOrderType(
                            areaHook,
                            areaHook.deliveryCharges,
                            orderTypeLocal
                          );

                          dispatch(selectAreaToggle(false));
                        }
                      } else {
                        // dispatch(selectArea(areaHook));
                        // dispatch(setDeliveryCharge(areaHook.deliveryCharges));
                        // changeOrderType(orderTypeLocal, areaHook.deliveryCharges);
                        changeCustomerOrderType(areaHook, areaHook.deliveryCharges, orderTypeLocal);

                        dispatch(selectAreaToggle(false));
                      }
                      // HERE

                      // dispatch(
                      //   setOrderType({
                      //     orderTypeLocal,
                      //     deliveryCharges: areaHook.deliveryCharges,
                      //   })
                      // );
                    }
                  }
              : () => {
                  if (separateTimings) {
                    if (
                      chosenInterval === null &&
                      orderTypeLocal === "delivery" &&
                      showDeliveryIntervalSelection()
                    ) {
                      showSnack(t("Please Select A Time First"), "warning");
                    } else if (
                      chosenInterval === null &&
                      orderTypeLocal === "pickup" &&
                      showPickupIntervalSelection()
                    ) {
                      showSnack(t("Please Select A Time First"), "warning");
                    } else if (chosenInterval !== null) {
                      dispatch(
                        setOrderTime({
                          time: chosenInterval,
                          delayed: true,
                        })
                      );
                      changeOrderType(orderTypeLocal, 0);
                      // dispatch(setOrderType({ orderTypeLocal, deliveryCharges: 0 }));
                      dispatch(setDeliveryCharge(0));
                      dispatch(selectAreaToggle(false));
                    } else {
                      changeOrderType(orderTypeLocal, 0);
                      // dispatch(setOrderType({ orderTypeLocal, deliveryCharges: 0 }));
                      dispatch(setDeliveryCharge(0));
                      dispatch(selectAreaToggle(false));
                    }
                  } else {
                    changeOrderType(orderTypeLocal, 0);
                    // dispatch(setOrderType({ orderTypeLocal, deliveryCharges: 0 }));
                    dispatch(setDeliveryCharge(0));
                    dispatch(selectAreaToggle(false));
                  }
                }
          }
          autoFocus
          fontSize="large"
        >
          {t("orderQueryDialog.done")}
        </Button>
      </span>
    </>
  );

  return (
    // <div>
    <Dialog

      sx={{
        "& .MuiDialog-paper": {
          maxHeight: orderTypeLocal !== "" ? "90vh" : "unset",
        },
        zIndex:100000
      }}
      open={showAreaSelection}
      onClose={() => {
        // to make sure user selects an orderType if separateTimings is on
        if (separateTimings && orderTypeCart === "") {
          // console.log("Please Select a type first");
          showSnack(t("Please Select An Order Type First"), "warning");
        } else {
          dispatch(selectAreaToggle(false));
          setSearchContent("");
        }
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        id="alert-dialog-title"
        sx={{ bgcolor: "background.default" }}
        className="DialogTitle"
      >
        <Typography sx={{ color: "gray" }} variant="subtitle1">
          {t("orderQueryDialog.heading")}
        </Typography>
        <Box className="flex ">{actionBtn}</Box>
      </DialogTitle>

      <DialogContent className="DialogContent relative" sx={{ bgcolor: "background.default" }}>
        {/* grid grid-cols-1 lg:grid-cols-2  justify-items-center */}

        {separateTimings &&
          isShopCurrentlyOpen?.status === false &&
          isShopCurrentlyOpen?.message === "less" && (
            <div className="flex flex-col gap-2 mb-4">
              <ShopOpeningTime />
              <Typography>{t("timingDialog.weClosedMessage")}</Typography>
            </div>
          )}
        {userAction !== "register" && (
          <>
            <div className="mb-[16px]  flex items-center justify-center gap-16 ">
              {/* ###################################################################### */}
              {/* ######################### PICKUP / DELIVERY CARDS #################### */}
              {/* ###################################################################### */}

              {/* ######################### DELIVERY CARD #################### */}
              {/* && !todayTiming?.holiday */}
              {/* it is now removed because holidays now have their own separateTimings i-e, DT and PT */}

              {!isDeliveryDisable && (
                <div
                  style={{
                    border:
                      orderTypeLocal === "delivery"
                        ? `1px solid ${theme.palette.primary.main}`
                        : "",
                    pointerEvents: separateTimings
                      ? isDeliveryAvailable?.status === false &&
                        isDeliveryAvailable?.message !== "less"
                        ? "none"
                        : ""
                      : "",
                    // pointerEvents:
                    //   separateTimings && !todayTiming?.holiday
                    //     ? isDeliveryAvailable?.status === false && "none"
                    //     : false,

                    // borderColor: theme.palette.primary.main
                  }}
                  role="button"
                  onClick={
                    deliveryON
                      ? () => setOrderTypeLocal("delivery")
                      : () =>
                          showSnack(
                            t("orderQueryDialog.Delivery not available at this time"),
                            "warning"
                          )
                  }
                  // ${
                  //   settings?.separateTimings
                  //     ? "grid-rows-[auto_25px_25px_auto]"
                  //     : "grid-rows-[auto_25px_25px]"
                  // }
                  // min-w-[185px]
                  className={`py-2 ${
                    separateTimings ? "w-[155px] min-h-[200px]" : "w-[155px] h-[175px]"
                  }   flex flex-col justify-center items-center shadow-2xl rounded-lg cursor-pointer relative   `}
                >
                  <div
                    style={{
                      filter:
                        orderTypeLocal === "delivery" && settings.deliveryON === true
                          ? ""
                          : (orderTypeLocal === "" || isDeliveryAvailable?.status !== false) &&
                            settings.deliveryON === true
                          ? ""
                          : "grayscale(1) Contrast(0.5) brightness(1.4)",
                    }}
                    className="flex flex-col justify-center"
                  >
                    <div className="flex  items-end justify-center ">
                      <img
                        className="w-[110px] h-[75px]"
                        src="/media/delivery-man.png"
                        alt="Delivery Icon"
                      />
                    </div>
                    <div className="flex items-center justify-center gap-2  p-1  w-full">
                      <b> {t("button.delivery")}</b>
                      <b>
                        {discounts?.deliveryDiscountActive
                          ? "-" + discounts?.deliveryDiscount + "%"
                          : null}
                      </b>
                    </div>
                    {defaultDeliveryTime !== "manual" ? (
                      <Box className="flex items-center justify-center gap-1 ">
                        <FaClock fontSize={".8rem"} />
                        <Typography variant="caption" fontSize={".8rem"}>
                          {defaultDeliveryTime} mins
                        </Typography>
                      </Box>
                    ) : null}
                    {/* !todayTiming?.holiday  */}
                    {settings?.separateTimings && (
                      <Box
                        sx={{
                          opacity: isDeliveryAvailable?.status === false ? 1 : 0,
                          pointerEvents: !isDeliveryAvailable?.status && "none",
                          display: isDeliveryAvailable?.status === false ? "flex" : "none",
                          alignItems: "center",
                          justifyContent: "center",
                          flexDirection: "column",
                          pb: 0,
                          // mt: 1,
                        }}
                      >
                        <Typography variant="caption">{t("Available only")}</Typography>
                        <ShowTime
                          fontWeight={"400"}
                          timings={todayTiming?.deliveryTimings}
                          styleType={"col"}
                        />
                      </Box>
                    )}
                  </div>

                  {/* INTERVALS */}
                  {/* !todayTiming?.holiday && */}
                  {settings?.separateTimings &&
                    isDeliveryAvailable?.status === false &&
                    isDeliveryAvailable?.message === "less" && (
                      <Box className="px-2 mt-1 w-full">
                        <IntervalSelector
                          setChosenInterval={setChosenInterval}
                          intervals={deliveryIntervals}
                          chosenInterval={chosenInterval}
                        />
                      </Box>
                      // <IntervalAccordion
                      //   setChosenInterval={setChosenInterval}
                      //   intervals={deliveryIntervals}
                      // />
                    )}
                </div>
              )}
              {/* ##################################### PICKUP CARD #######################  */}
              {/* && !todayTiming?.holiday */}
              <div
                style={{
                  border:
                    orderTypeLocal === "pickup" ? `1px solid ${theme.palette.primary.main}` : "",
                  pointerEvents: separateTimings
                    ? isPickupAvailable?.status === false && isPickupAvailable?.message !== "less"
                      ? "none"
                      : ""
                    : "",
                  // pointerEvents:
                  //   separateTimings && !todayTiming?.holiday
                  //     ? isPickupAvailable?.status === false && "none"
                  //     : false,
                  // borderColor: theme.palette.primary.main
                }}
                onClick={
                  pickupON
                    ? () => setOrderTypeLocal("pickup")
                    : () =>
                        showSnack(
                          t("orderQueryDialog.Pickup not available at this time"),
                          "warning"
                        )
                }
                className={`py-2 ${
                  separateTimings ? "w-[155px] min-h-[200px]" : "w-[155px] h-[175px]"
                } flex flex-col justify-center  shadow-2xl rounded-lg cursor-pointer  `}
              >
                <div
                  style={{
                    filter:
                      orderTypeLocal === "pickup"
                        ? ""
                        : orderTypeLocal === "" || isPickupAvailable?.status !== false
                        ? ""
                        : "grayscale(1) Contrast(0.5) brightness(1.4)",
                  }}
                  className="flex flex-col justify-center"
                >
                  <div className="flex items-center justify-center ">
                    <img
                      className="w-[90px] h-[77px]"
                      src="/media/take-away.png"
                      alt="Pickup Icon"
                    />
                  </div>

                  <div className="flex items-center justify-center gap-2  p-1  w-full">
                    <b className="text-">{t("button.pickup")}</b>
                    <b>
                      {discounts?.pickupDiscountActive
                        ? "-" + discounts?.pickupDiscount + "%"
                        : null}
                      {/* {orderTypeLocal === "pickup" ? <Done fontSize="small" /> : null} */}
                    </b>
                  </div>
                  {defaultPickupTime !== "manual" ? (
                    <Box className="flex items-center justify-center gap-1 ">
                      <FaClock fontSize={".8rem"} />
                      <Typography variant="caption" fontSize={".8rem"}>
                        {defaultPickupTime} mins
                      </Typography>
                    </Box>
                  ) : null}
                  {/* && !todayTiming?.holiday */}
                  {settings?.separateTimings && (
                    <Box
                      sx={{
                        opacity: isPickupAvailable?.status === false ? 1 : 0,
                        pointerEvents: isPickupAvailable?.status === false && "none",
                        // pointerEvents: isPickupAvailable?.status === false && "none",
                        display: isPickupAvailable?.status === false ? "flex" : "none",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                        pb: 0,
                        mt: 1,
                        //     separateTimings
                        // separateTimings ()new
                      }}
                    >
                      {/* // Nur verfügbar // Available only */}
                      <Typography variant="caption">{t("Available only")}</Typography>
                      <ShowTime
                        fontWeight={"400"}
                        timings={todayTiming?.pickupTimings}
                        styleType={"col"}
                      />
                    </Box>
                  )}
                </div>

                {/* Interval Selector */}
                {/* !todayTiming?.holiday && */}
                {settings?.separateTimings &&
                  isPickupAvailable?.status === false &&
                  isPickupAvailable?.message === "less" && (
                    <Box className="px-2 w-full">
                      <IntervalSelector
                        setChosenInterval={setChosenInterval}
                        intervals={pickupIntervals}
                        chosenInterval={chosenInterval}
                      />
                    </Box>
                    // <IntervalAccordion
                    //   setChosenInterval={setChosenInterval}
                    //   intervals={pickupIntervals}
                    // />
                  )}
              </div>
            </div>
          </>
        )}

        {orderTypeLocal === "delivery" || userAction === "register" ? (
          currUser ? (
            // <div className="p-2  border border-1 rounded-md">
            //   <div style={{ display: "inline-flex", width: "100%" }}>
            //     <ul
            //       style={{
            //         width: "50%",
            //         fontSize: "16px",
            //         // color: "grey",
            //         padding: "0",
            //       }}
            //     >
            //       <li>{currUser.name}</li>
            //       <li>{currUser.email}</li>
            //       <li>{currUser.phone}</li>
            //     </ul>
            //     {/* {orderType === "delivery" ? ( */}
            //     <ul
            //       style={{
            //         width: "50%",
            //         fontSize: "16px",
            //         // color: "grey",
            //         padding: "0",
            //       }}
            //     >
            //       <li style={{ textAlign: "right" }}>
            //         {userAddress.street}, {t("forms.Floor")}:{userAddress.floor}
            //       </li>
            //       <li style={{ textAlign: "right" }}>
            //         {t("forms.Bell Name")}:{userAddress.bellName}
            //       </li>
            //       <li style={{ textAlign: "right" }}>
            //         {userAddress.postalCode}, {userAddress.city}
            //       </li>
            //     </ul>
            //     {/* ) : null} */}
            //   </div>
            // </div>
            <UserData isCart={true} />
          ) : (
            <SlideDiv>
              <React.Fragment>
                <div
                  style={{
                    paddingTop: "5px",
                    textAlign: "center",
                    fontSize: "15px",
                    color: "#737373",
                  }}
                >
                  {t("orderQueryDialog.selectArea")}
                </div>

                <div className="AreaListContainer">
                  {/*  */}
                  <div className="  top-0 ">
                    <input
                      style={{
                        bgcolor: "background.default",
                        // backgroundColor: "white",
                        // height: "100%",
                        // color: "text.primary",
                        // theme.palette.mode === "dark"
                        color: theme.palette.mode === "dark" ? "black" : "text.primary",
                      }}
                      className="searchInput"
                      type="text"
                      placeholder={t("Search Area")}
                      onChange={(e) => handleChange(e)}
                      value={searchContent}
                    />
                  </div>

                  <ul
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      padding: "0",
                      // height:  "120px",
                      height: screenHeight > 900 ? "100%" : "auto",
                      overflowY: "auto",
                    }}
                  >
                    {searchContent?.length === 0 &&
                      areas
                        .filter((f) => f.isDefault)
                        .map((area, id) => {
                          return (
                            <button
                              disabled={!area.active}
                              className={
                                isItemSelected === area
                                  ? "AreaButton bg-gray-400"
                                  : // : selectedArea.id === area.id
                                    // ? "AreaButton itemSelected"
                                    "AreaButton"
                              }
                              onClick={() => {
                                setArea(area);
                                setIsItemSelected(area);
                              }}
                              style={{
                                color: area.active ? "text.primary" : "gray",
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                              key={id}
                            >
                              <li className="AreaOption" key={id}>
                                <span>{area.postalCode + " " + area.area}</span>
                                {/* <div className="circle"></div> */}
                              </li>
                              {!area.active && (
                                <li className="text-[12px]">
                                  {t("Delivery not available at this time")}
                                </li>
                              )}
                            </button>
                          );
                        })}

                    {sortedDeliveryAreas
                      ?.filter((f) => !f.isDefault)
                      .map((area, id) => {
                        return (
                          <button
                            disabled={!area.active}
                            className={
                              isItemSelected === area
                                ? "AreaButton bg-gray-400"
                                : // : selectedArea.id === area.id
                                  // ? "AreaButton bg-gray-400"
                                  "AreaButton"
                            }
                            style={{
                              color: area.active ? "text.primary" : "gray",
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                            onClick={() => {
                              setArea(area);
                              setIsItemSelected(area);
                            }}
                            key={id}
                          >
                            <li className="AreaOption" key={id}>
                              {area.postalCode + " " + area.area}
                            </li>
                            {!area.active && (
                              <li className="text-[12px]">
                                {t("Delivery not available at this time")}
                              </li>
                            )}
                          </button>
                        );
                      })}
                  </ul>
                </div>
              </React.Fragment>
            </SlideDiv>
          )
        ) : orderTypeLocal === "pickup" ? (
          <SlideDiv>
            <Paper elevation={3} className="ShopAddressContainer">
              <div
                className="ShopAddressMap"
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div className="ShopMap">
                  <ShopMap />
                </div>
                <div className="ShopAddress">
                  <h3 style={{ color: "grey" }}>{t("orderQueryDialog.ourAddress")}</h3>
                  <p>{shopData.street}</p>
                  <p>
                    {shopData.postalCode}, {shopData.city}
                  </p>
                  {/* <p>{shopData.phone}</p> */}
                  <p className="flex gap-4">
                    {shopData?.phoneNumbers?.map((phoneNumber) => {
                      return <span>{phoneNumber.phone}</span>;
                    })}
                  </p>
                  <p>{shopData.email}</p>
                </div>
              </div>
            </Paper>
          </SlideDiv>
        ) : null}

        <DialogContentText
          sx={{ fontSize: ".8rem" }}
          id="alert-dialog-description"
          className="ContentText"
        >
          *{t("orderQueryDialog.info")}.
        </DialogContentText>
      </DialogContent>

      <DialogActions className="MuiDialogActions">
        <div style={{ padding: "5px 10px 5px 10px", display: "inline-flex" }}>
          {orderTypeLocal !== "delivery" || (currUser && !addingNewAddress) ? null : (
            <span>
              {areaHook ? (
                <div className="AreaChip">
                  <Chip label={areaHook.area + " " + areaHook.postalCode} />
                </div>
              ) : selectedArea?.id ? (
                <div className="AreaChip">
                  <Chip label={selectedArea?.postalCode + " " + selectedArea?.area} />
                </div>
              ) : (
                <div className="AreaChip">
                  <Chip label={t("orderQueryDialog.noArea")} />
                </div>
              )}
            </span>
          )}
          {/* <Button variant="outlined" color="inherit"
                        onClick={() => props.selectAreaToggle(false)}>
                        Cancel
                    </Button> */}
          {actionBtn}
        </div>
      </DialogActions>
    </Dialog>
    // </div>
  );
};

export default AreaSelection;
