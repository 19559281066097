import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import "./InputForm.css";
import { useTranslation } from "react-i18next";

import {
  Select,
  MenuItem,
  TextField,
  FormHelperText,
  FormControl,
  Button,
  Typography,
  Box,
  Checkbox,
  Autocomplete,
  InputLabel,
} from "@mui/material";

import { CheckBox, EditLocationAlt } from "@mui/icons-material";
import { selectArea, selectAreaToggle } from "../../../../ToolKit/Slices/OrderSlice";
import { OpenStreetMapProvider } from "leaflet-geosearch";
import { sortAreas } from "../../../../ToolKit/Slices/utils";
import { selectAddressLocal } from "../../../../ToolKit/Slices/UserSlice";
import { setDeliveryCharge, setOrderType } from "../../../../ToolKit/Slices/CartSlice";
import { use } from "i18next";

const InputForm = (props) => {
  const area = useSelector((state) => state.order.selectedArea);
  const delayedTime = useSelector((state) => state.order.delayedTime);
  const delayed = useSelector((state) => state.order.delayedOrder);
  const serverTime = useSelector((state) => state.shop.serverTime);
  const isShopCurrentlyOpen = useSelector((state) => state.shop.isShopCurrentlyOpen);
  const currentShopTimeArray = useSelector((state) => state.shop.isShopCurrentlyOpen?.time);
  const isOfferTimeItemSelected = useSelector((state) => state.order.isOfferTimeItemSelected);
  const selectedCategoryOfferTimes = useSelector((state) => state.order.selectedCategoryOfferTimes);
  const separateTimings = useSelector((state) => state.shop.settings?.separateTimings);
  const isDeliveryAvailable = useSelector((state) => state.shop.isDeliveryAvailable);
  const isPickupAvailable = useSelector((state) => state.shop.isPickupAvailable);

  const dispatch = useDispatch();

  // const serverTime = 2035;

  const { t } = useTranslation();

  let inputElement = null;
  let isValid = false;
  const currentShopMinutes = currentShopTimeArray?.[1]?.toString().slice(2, 4);
  // console.log('currentShopMinutes', currentShopMinutes)
  const {
    shouldValidate,
    elementConfig,
    changed,
    value,
    invalid,
    touched,
    elementType,
    timeIntervals,
    shopOpen,
    userAction,
    saveDetails,
    orderType,
    formElement,
  } = props;

  if (invalid && shouldValidate && touched) {
    isValid = true;
  }

  const provider = new OpenStreetMapProvider();
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState([]);

  const areas = useSelector((state) => state.shop.deliveryAreas);
  const selectedArea = useSelector((state) => state.order.selectedArea);
  const [sortedDeliveryAreas, setSortedDeliveryAreas] = useState(null);
  const sortingObj = useSelector((state) => state.shop.settings.deliveryAreaSorting);
  const deliveryON = useSelector((state) => state.shop.deliveryON);
  const addresses = useSelector((state) => state.user.addresses);
  const addingNewAddress = useSelector((state) => state.user.addingNewAddress);

  useEffect(() => {
    if (areas && areas?.length !== 0) {
      const notDefaultAreas = areas?.filter((f) => !f.isDefault);
      const newAreas = sortAreas(sortingObj, notDefaultAreas);
      setSortedDeliveryAreas(newAreas);
    }
  }, [areas]);

  useEffect(() => {
    if (inputValue === "") {
      setOptions([]);
      return;
    }

    const fetchData = async () => {
      const results = await provider.search({ query: inputValue });
      setOptions(results);
      console.log("results", results);
    };

    fetchData();
  }, [inputValue]);

  useEffect(() => {
    // console.log("formElement", formElement);

    // if(formElement?.id === "deliveryTime"){
    if (delayed && formElement?.id === "deliveryTime") {
      const event = {
        target: {
          value: delayedTime,
        },
      };
      console.log("before changead fired event", event);
      changed(event, "deliveryTime");
    }
    // }
  }, []);

  const checkErrorOccurence = () => {
    let result;
    if (separateTimings) {
      if (
        ((orderType === "pickup" &&
          !isPickupAvailable?.status &&
          isPickupAvailable?.message === "less") ||
          (orderType === "delivery" &&
            !isDeliveryAvailable?.status &&
            isDeliveryAvailable?.message === "less")) &&
        delayed
      ) {
        console.log("value=> ", value);

        if (value === "As soon as possible" || value === "Choose Time") {
          result = true;
        } else {
          result = false;
        }
      } else {
        console.log("else", value);
        if (value !== "As soon as possible" || delayed) {
          if (value === "") {
            result = true;
          } else {
            result = false;
          }
        } else {
          result = false;
        }
      }
      // if (shopOpen) {
      //   if (value === "") {
      //     result = true;
      //   } else {
      //     result = false;
      //   }
      // } else {
      //   if (value !== "As soon as possible" || delayed) {
      //     if (value === "") {
      //       result = true;
      //     } else {
      //       result = false;
      //     }
      //   } else {
      //     result = true;
      //   }
      // }
    } else {
      if (shopOpen) {
        if (value === "") {
          result = true;
        } else {
          result = false;
        }
      } else {
        if (value !== "As soon as possible" || delayed) {
          if (value === "") {
            result = true;
          } else {
            result = false;
          }
        } else {
          result = true;
        }
      }
    }

    return result;
  };

  const showSelectedTimeInterval = (delayedTime) => {
    const stringedInterval = delayedTime.toString();
    let modifiedInterval = "";
    if (stringedInterval.length === 2) {
      modifiedInterval = "00" + stringedInterval;
    } else if (stringedInterval.length === 3) {
      modifiedInterval = "0" + stringedInterval;
    } else if (stringedInterval.length === 4) {
      modifiedInterval = stringedInterval;
    } else {
      modifiedInterval = stringedInterval;
    }

    console.log("modifiedInterval", modifiedInterval);

    return modifiedInterval;
  };

  switch (elementType) {
    // case "leaflet":
    //   inputElement = (
    //     <Autocomplete
    //       freeSolo
    //       options={options}
    //       getOptionLabel={(option) => option.label || ""}
    //       renderInput={(params) => (
    //         <TextField
    //           {...params}
    //           fullWidth
    //           required={shouldValidate.required}
    //           color="secondary"
    //           type={elementConfig.type}
    //           variant="outlined"
    //           label={t(`forms.${elementConfig.placeholder}`)}
    //           margin="dense"
    //           helperText={isValid ? t(`forms.${elementConfig.error}`) : null}
    //           error={isValid}
    //           value={inputValue}
    //           onChange={(event) => setInputValue(event.target.value)}
    //         />
    //       )}
    //     />
    //   );
    //   break;
    case "input":
      inputElement = (
        <TextField
          fullWidth
          required={shouldValidate.required}
          color="secondary"
          type={elementConfig.type}
          variant="filled"
          label={t(`forms.${elementConfig.placeholder}`)}
          margin="dense"
          helperText={isValid ? t(`forms.${elementConfig.error}`) : null}
          error={isValid}
          value={value}
          onChange={changed}
        />
      );
      break;
    case "textarea":
      inputElement = (
        <TextField
          variant="outlined"
          label={t(`forms.${elementConfig.placeholder}`)}
          color="secondary"
          fullWidth
          multiline
          margin="dense"
          rows={3}
          error={isValid}
          value={value}
          onChange={changed}
        />
      );
      break;
    case "select":
      inputElement = (
        <div>
          <FormControl variant="outlined" size="small">
            <FormHelperText>
              {!shopOpen ? t("forms.shopClosedMessage") : t("forms.whenYouWantOrder")}
            </FormHelperText>
            <Select
              variant="outlined"
              color="secondary"
              margin="dense"
              value={value}
              onChange={changed}
              displayEmpty={false}
              error={checkErrorOccurence()}
              // error={
              //   shopOpen
              //     ? value === ""
              //       ? true
              //       : false
              //     : value !== "As soon as possible" || delayed
              //     ? value === ""
              //       ? true
              //       : false
              //     : true
              // }
              // t(`forms.${delayedTime}`)
              // disabled={
              //   isOfferTimeItemSelected &&
              //   selectedCategoryOfferTimes?.categoryTime === "between" &&
              //   isShopCurrentlyOpen?.status === true
              //     ? true
              //     : currentShopMinutes !== "59"
              //     ? serverTime >= currentShopTimeArray[1] - 170
              //     : serverTime >= currentShopTimeArray[1] - 59
              // }
              disabled={
                currentShopMinutes !== "59"
                  ? serverTime >= currentShopTimeArray[1] - 170
                  : serverTime >= currentShopTimeArray[1] - 59
              }
              // disabled={
              //   isOfferTimeItemSelected
              //     ? true
              //     : currentShopMinutes !== "59"
              //     ? serverTime >= currentShopTimeArray[1] - 170
              //     : serverTime >= currentShopTimeArray[1] - 59
              // }
              // || serverTime < 100 ? true : false
              renderValue={() =>
                delayed || !shopOpen ? (
                  isNaN(delayedTime) ? (
                    t(`forms.${delayedTime}`)
                  ) : (
                    <span>
                      {showSelectedTimeInterval(delayedTime).slice(0, 2)}:
                      {showSelectedTimeInterval(delayedTime).slice(2, 4)}
                    </span>
                  )
                ) : isNaN(value.slice(0, 2)) ? (
                  t(`forms.${value}`)
                ) : (
                  value
                )
              }
            >
              {isOfferTimeItemSelected
                ? timeIntervals
                    ?.filter(
                      (interval) =>
                        interval >= selectedCategoryOfferTimes?.offerStartTime &&
                        interval <= selectedCategoryOfferTimes?.offerEndTime
                    )
                    ?.map((time, index) => {
                      if (time.toString().length === 3) {
                        return (
                          <MenuItem key={index} value={time}>
                            {"0" + time.toString().slice(0, 1)}:{time.toString().slice(1, 3)}
                          </MenuItem>
                        );
                      } else if (time.toString().length === 4) {
                        return (
                          <MenuItem key={index} value={time}>
                            {time.toString().slice(0, 2)}:{time.toString().slice(2, 4)}
                          </MenuItem>
                        );
                      }
                    })
                : timeIntervals?.map((time, index) => {
                    if (time.toString().length === 3) {
                      return (
                        <MenuItem key={index} value={time}>
                          {"0" + time.toString().slice(0, 1)}:{time.toString().slice(1, 3)}
                        </MenuItem>
                      );
                    } else if (time.toString().length === 4) {
                      return (
                        <MenuItem key={index} value={time}>
                          {time.toString().slice(0, 2)}:{time.toString().slice(2, 4)}
                        </MenuItem>
                      );
                    }
                  })}

              {/* {timeIntervals?.map((time, index) => {
                if (time.toString().length === 3) {
                  return (
                    <MenuItem key={index} value={time}>
                      {"0" + time.toString().slice(0, 1)}:{time.toString().slice(1, 3)}
                    </MenuItem>
                  );
                } else if (time.toString().length === 4) {
                  return (
                    <MenuItem key={index} value={time}>
                      {time.toString().slice(0, 2)}:{time.toString().slice(2, 4)}
                    </MenuItem>
                  );
                }
               })} */}
            </Select>
          </FormControl>
        </div>
      );
      break;
    default:
      inputElement = (
        <TextField
          color="secondary"
          id="outlined-basic"
          {...elementConfig}
          value={value}
          onChange={changed}
        />
      );
  }

  return (
    <div>
      {elementConfig.placeholder === "Street" ? (
        <h4 className="FormHeadings">{t("forms.enterDeliveryAddress")}</h4>
      ) : null}
      {elementConfig.placeholder === "Full Name" ? (
        <>
          {orderType === "kiosk" ? (
            <h4 className="FormHeadings">
              Der Name ist nicht Pflicht. Ihr Name wird nicht am Bildschirm angezeigt.
            </h4>
          ) : (
            <h4 className="FormHeadings">{t("forms.enterContactDetails")}</h4>
          )}
        </>
      ) : null}
      {inputElement}
      {elementConfig.placeholder === "E-Mail" &&
        userAction !== undefined &&
        userAction === "guest" &&
        orderType !== undefined &&
        orderType === "delivery" && (
          <Box className="flex  items-center gap-1 my-2">
            <Checkbox
              onChange={(e) => {
                if (e.target.checked) {
                  saveDetails();
                }
              }}
              value={false}
            />
            <Typography
              variant="subtitle1"
              sx={{
                color: "text.primary",
              }}
            >
              {t("Save my details for next time")}
            </Typography>
          </Box>
        )}
      {elementConfig.placeholder === "Bell Name" ? (
        <div className="PostalCodeField">
          <Typography
            variant="subtitle1"
            sx={{
              color: "text.primary",
            }}
          >
            {t("forms.area")}:
          </Typography>

          {/* <Button
            size="small"
            endIcon={<EditLocationAlt />}
            variant="outlined"
            onClick={() => dispatch(selectAreaToggle(true))}
          >
            {area?.postalCode ? area?.postalCode + ", " + area?.area : t("forms.selectPostalCity")}
          </Button> */}
          <FormControl
            required={true}
            sx={{
              my: 2,
            }}
            size="small"
            fullWidth
          >
            <InputLabel id="demo-simple-select-label">Select Area</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={selectedArea}
              label="Select Area"
              onChange={(event) => {
                const deliveryCharges = areas.find(
                  (area) => area.id === event.target.value.id
                ).deliveryCharges;
                dispatch(selectArea(areas.find((area) => area.id === event.target.value.id)));

                dispatch(setDeliveryCharge(deliveryCharges));
                if (!addingNewAddress) {
                  dispatch(setOrderType({ orderTypeLocal: "delivery", deliveryCharges }));
                }
              }}
            >
              {sortedDeliveryAreas
                ?.filter((f) => !f.isDefault)
                .map((area, id) => {
                  return (
                    <MenuItem
                      key={id}
                      disabled={!area.active}
                      value={area}
                      // onClick={}
                    >
                      <li className="AreaOption" key={id}>
                        {area.postalCode + " " + area.area}
                      </li>
                      {!area.active && (
                        <li className="text-[12px]">{t("Delivery not available at this time")}</li>
                      )}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        </div>
      ) : null}
    </div>
  );
};

export default InputForm;
