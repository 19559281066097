import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import "./TimingsDialog.css";

import { Close, ExpandMore, ExpandLess } from "@mui/icons-material";

import {
  Button,
  Slide,
  DialogActions,
  DialogTitle,
  DialogContentText,
  DialogContent,
  IconButton,
  Dialog,
  Box,
  Typography,
} from "@mui/material";

import TimingsTable from "../ShopData/TimingsTable/TimingsTable";
import DelayedOrder from "./DelayedOrder";

import { useTranslation } from "react-i18next";
import { timeDialogToggle } from "../../../ToolKit/Slices/ShopSlice";
import ShowTime from "../ShopData/TimingsTable/ShowTime";
import ShopOpeningTime from "../../CategoryNav/ShopOpeningTime";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function TimingDialog(props) {
  const { t } = useTranslation();
  const dialogVisible = useSelector((state) => state.shop.timeDialogVisible);
  const todayTime = useSelector((state) => state.shop.todayTiming);
  const manuallyClosed = useSelector((state) => state.shop.manuallyClosed);
  const manualClosingReason = useSelector(
    (state) => state.shop.settings?.manualClosing?.reason
  );
  const isOrderPossible = useSelector((state) => state.shop.isOrderPossible);
  const delayed = useSelector((state) => state.order.delayedOrder);
  const shopTimingsModified = useSelector(
    (state) => state.shop.shopTimingsModified
  );
  const isShopCurrentlyOpen = useSelector(
    (state) => state.shop.isShopCurrentlyOpen
  );
  const separateTimings = useSelector(
    (state) => state.shop?.settings?.separateTimings
  );
  const isDeliveryDisable = useSelector(
    (state) => state.shop.settings?.isDeliveryDisable
  );
  const dispatch = useDispatch();
  const [open, setOpen] = useState(dialogVisible && !delayed);
  const [viewOpenings, setView] = useState(false);
  const [todaysTimings, setTodaysTimings] = useState(null);

  useEffect(() => {
    // nextDayTimings[0][0] === "0000"
    // THE thing we did when next days start time is 0000
    if (shopTimingsModified) {
      if (!todayTime?.holiday) {
        const filteredDay = shopTimingsModified.filter(
          (f) => f.day === todayTime.day
        );

        setTodaysTimings(filteredDay[0].timings);
      } else if (todayTime?.holiday) {
        setTodaysTimings(todayTime?.timings);
      }
    }
  }, [shopTimingsModified, todayTime]);

  const handleClose = () => {
    setOpen(false);
    dispatch(timeDialogToggle(false));
  };

  console.log("todayTime", todayTime);
  return (
    <div>
      <Dialog
        sx={{
          "& .MuiPaper-root": {
            width: "95%",
          },
        }}
        TransitionComponent={Transition}
        open={open}
        fullWidth
        maxWidth="sm"
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title" className="title">
          {isShopCurrentlyOpen?.status === false &&
            isShopCurrentlyOpen?.message === "less" && <ShopOpeningTime />}
          {manuallyClosed
            ? t("timingDialog.Temporarily Closed")
            : todayTime?.open
            ? !isOrderPossible
              ? t("timingDialog.closedAlready")
              : t("timingDialog.weAreNotOpenYet")
            : t("timingDialog.weAreClosedToday")}

          <IconButton
            edge="end"
            color="inherit"
            className="closeCross"
            onClick={handleClose}
            aria-label="close"
            style={{ float: "right" }}
          >
            <Close />
          </IconButton>
        </DialogTitle>

        <DialogContent
          sx={{
            display: "flex",

            alignItems: "start",
            gap: "10px",
          }}
          className="max-sm:flex-col  p-0"
        >
          <div className="flex max-sm:justify-center max-sm:w-full">
            <img
              className="w-[140px] max-sm:w-[100px]"
              src="/media/clock.png"
              alt="Clock Icon"
            />
          </div>

          <div className="flex flex-col w-full gap-4">
            <DialogContentText>
              {manuallyClosed ? (
                <span>
                  <span>
                    {t("timingDialog.We are currently closed due to")}
                  </span>
                  <br />
                  <b>{manualClosingReason}</b>
                </span>
              ) : todayTime?.open ? (
                !isOrderPossible ? (
                  t("timingDialog.closedNowforToday")
                ) : (
                  t("timingDialog.weClosedMessage")
                )
              ) : (
                t("timingDialog.weClosedTodayMessage")
              )}
            </DialogContentText>

            {manuallyClosed ? null : todayTime?.open ? (
              <div className="flex flex-col gap-4">
                {isOrderPossible ? <DelayedOrder /> : null}
                {/* && !todayTime?.holiday */}
                {!separateTimings && (
                  <h4
                    className={`flex flex-col ${
                      todayTime?.timings.length < 2 ? "md:flex-row" : null
                    }  gap-2 `}
                  >
                    <span>
                      {todayTime?.holiday
                        ? todayTime?.day
                        : t(`days.${todayTime?.day}`)}
                      &nbsp;
                      {t("timingDialog.openingTime")}:
                    </span>
                    {/* :&nbsp; */}
                    <div className="flex items-end">
                      <ShowTime timings={todaysTimings} fontWeight={"400"} />
                      {/* <ShowTime timings={todayTime?.timings} /> */}
                    </div>
                  </h4>
                )}
                {/* && !&& !todayTime?.holiday */}
                {separateTimings && (
                  <h4>
                    {/* Delivery Timings */}
                    {todayTime?.holiday ? (
                      <Typography variant="h6">{todayTime?.day}</Typography>
                    ) : (
                      <Typography variant="h6">
                        {t(`days.${todayTime?.day}`)}
                      </Typography>
                    )}
                    {/* <Typography variant="h6">{t(`days.${todayTime?.day}`)}</Typography> */}

                    {!isDeliveryDisable && (
                      <div className="flex items-end gap-2 ">
                        <span>{t("Delivery Time")}:</span>
                        {/* :&nbsp */}
                        <div className="flex items-end">
                          <ShowTime
                            timings={todayTime?.deliveryTimings}
                            fontWeight={"400"}
                          />
                        </div>
                      </div>
                    )}
                    {/* pickup Timings */}
                    <div className="flex items-end gap-2 ">
                      <span>{t("Pickup Time")}:</span>
                      <div className="flex items-end">
                        <ShowTime
                          timings={todayTime?.pickupTimings}
                          fontWeight={"400"}
                        />
                      </div>
                    </div>
                  </h4>
                )}
              </div>
            ) : (
              <React.Fragment>
                <Box className="flex flex-col md:flex-row gap-2 ">
                  {/* t(`days.${todayTime?.day}`) */}
                  <h4>
                    {todayTime?.holiday
                      ? todayTime?.day
                      : t(`days.${todayTime?.day}`)}
                    :{/* {t("timingDialog.openingTime")} */}
                  </h4>

                  {todayTime?.open ? (
                    <span className="">
                      <ShowTime
                        timings={todayTime?.deliveryTimings}
                        fontWeight={"400"}
                      />
                      {/* <ShowTime timings={todayTime?.timings} /> */}
                    </span>
                  ) : (
                    t("Closed")
                  )}
                </Box>
                {/* <p>
                  {t("shopTimings.dayTime")}: <ShowTime timings={todayTime.timings} />
                  {todayTime.openTimeDay || todayTime.closeTimeDay
                    ? todayTime.openTimeDay.slice(0, 2) +
                      ":" +
                      todayTime.openTimeDay.slice(2) +
                      "-" +
                      (todayTime.closeTimeDay.slice(0, 2) + ":" + todayTime.closeTimeDay.slice(2))
                    : "Closed"}
                </p> */}
                <p>
                  {/* {t("shopTimings.eveningTime")}: bye
                  <ShowTime timings={todayTime.timings} /> */}
                  {/* {todayTime.openTimeEvening || todayTime.closeTimeEvening
                    ? todayTime.openTimeEvening.slice(0, 2) +
                      ":" +
                      todayTime.openTimeEvening.slice(2) +
                      "-" +
                      (todayTime.closeTimeEvening.slice(0, 2) +
                        ":" +
                        todayTime.closeTimeEvening.slice(2))
                    : "Closed"} */}
                </p>
              </React.Fragment>
            )}

            {/* {isShopCurrentlyOpen?.status === false && isShopCurrentlyOpen?.message === "less" && (
              <ShopOpeningTime />
            )} */}
            {manuallyClosed ? null : (
              <div className="ViewAllTimings w-full">
                <Button
                  fullWidth
                  variant="outlined"
                  color="primary"
                  onClick={() => setView(!viewOpenings)}
                >
                  {t("button.viewOpeningTimes")}{" "}
                  {viewOpenings ? <ExpandLess /> : <ExpandMore />}
                </Button>

                {viewOpenings && <TimingsTable timings={shopTimingsModified} />}
              </div>
            )}
          </div>
        </DialogContent>
        <DialogActions style={{ paddingRight: 20 }}>
          <Button variant="contained" color="primary" onClick={handleClose}>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
