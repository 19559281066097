import React from "react";
import { useSelector } from "react-redux";

import { Paper } from "@mui/material";

import { IconContext } from "react-icons";
import { FaPaypal } from "react-icons/fa";
import { IoIosCash } from "react-icons/io";

import "./PaymentMethods.css";
import PCMPayment from "../../UI/SVGIcons/PCMPayment";

function PaymentMethods() {
  const paymentMethods = useSelector((state) => state.shop.paymentMethods);
  const stripeMethodUrls = useSelector((state) => state.order.paymentMethodUrls);

  return (
    <div className="PaymentMethods">
      {paymentMethods?.map((method, index) => {
        if (method.isActive)
          return (
            <>
              {method.method !== "Stripe" && (
                <Paper
                  className="PaymentMethodsPaper2 w-[40px] min-h-0 cursor-default"
                  key={index}
                  elevation={0}
                  value={method}
                >
                  <div className="flex items-center justify-center ">
                    <IconContext.Provider value={{ size: "2em" }}>
                      {method.method === "Cash on Delivery" ? (
                        <IoIosCash color="#61b585" />
                      ) : method.method === "Paypal" ? (
                        <FaPaypal color="rgb(26, 76, 197)" />
                      ) : (
                        <PCMPayment color={"#000"} height={"30px"} width={"30px"} />
                      )}
                    </IconContext.Provider>
                  </div>
                </Paper>
              )}

              {/* this is for stripe */}
              {/* becoz stripe has many logos */}
              {method.method === "Stripe" &&
                stripeMethodUrls?.map((el) => {
                  return (
                    <Paper
                      className="PaymentMethodsPaper2 w-[40px] min-h-0 cursor-default"
                      key={index}
                      elevation={0}
                      value={method}
                    >
                      <IconContext.Provider value={{ size: "2em" }}>
                        <img className="  " src={el} alt="payment methods stripe" />
                      </IconContext.Provider>
                    </Paper>
                  );
                })}
            </>
          );
      })}
    </div>
  );
}

export default PaymentMethods;

// : method.method === "Stripe" ? (
//   // <div className="flex items-center flex-wrap gap-3">
//   <div className="flex items-center flex-wrap">
//     {stripeMethodUrls?.map((el) => {
//       return (
//         <img
//           className=" w-[20px] h-[20px]"
//           src={el}
//           alt="payment methods stripe"
//         />
//       );
//     })}
//   </div>
// )
