import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import axios from "axios";

// importing actions
import { showCartToggle, resetCart } from "./CartSlice";
import { updateUserOrders } from "./UserSlice";
import { resetVoucher } from "./ShopSlice";
import { addNotification } from "./NotifierSlice";

// imorting thunks
import { registerWithOrder } from "./UserSlice";

// Initial State

const initialState = {
  paymentMethodUrls: [],
  localOrders: [],
  isOfferTimeItemSelected: false,
  selectedCategoryOfferTimes: null,
  // selectedArea: null,
  selectedArea: {},
  showAreaSelection: false,
  currOrder: null,
  currOrderStatus: null,
  delayedOrder: false,
  delayedTime: "Choose Time",
  stripeSession: null,
  socketId: null,

  loading: false,
  pLoading: false,
  error: null,
  pError: null,

  newOrderPlaced: false,

  ordersContainerVisible: false,
  currOrdersTrackers: [],

  incompleteOrdersCount: 0,
  isEighteenPlusItemDialogOpen: false,
  eighteenPlusItem: null,
  cartCheckout: false,
  stripeButtonLoading: false,
};

// ####################### ASYNC THUNKS

// 1. PLACE ORDER

// import RESET_CART from cartSlice
export const placeOrder = createAsyncThunk(
  "/order/place_order",
  async ({ orderData, userAction, currUser }, thunkApi) => {
    const { rejectWithValue, dispatch } = thunkApi;

    try {
      const password = orderData?.userData?.password;
      const confirmPassword = orderData?.userData?.confirmPassword;

      const orderToSend = { ...orderData };
      dispatch(showCartToggle());
      if (orderToSend?.userData?.password) {
        delete orderToSend?.userData?.password;
        delete orderToSend?.userData?.confirmPassword;
      }
      const response = await axios.post(
        `${import.meta.env.VITE_API_URL}/order/place_order`,
        orderToSend
      );

      // ###### update service worker with new id

      const subscription = JSON.parse(localStorage.getItem("subscription"));
      if (subscription) {
        axios.post(`${import.meta.env.VITE_API_URL}/push-noty/update-subscription-user`, {
          ...subscription,
          orderId: currUser ? response.data.id : response.data.order.id,
        });
      }
      // done service worker
      let orderDa = response.data.order;
      if (currUser) {
        dispatch(updateUserOrders({ newOrder: response.data }));
        orderDa = response.data;
      } else {
        axios.defaults.headers.common.Authorization = `Bearer ${response.data.token}`;
        localStorage.setItem("token", response.data.token);
      }
      dispatch(resetCart(false));
      dispatch(resetVoucher());
      dispatch(toggleNewOrder(true));
      dispatch(ordersContainerToggle(true));
      dispatch(setCartCheckout(false));
      dispatch(setIsOfferTimeItemSelected(false));
      // console.log('orderData', orderData)
      if (userAction === "register") {
        let dataToRegister = { ...orderDa?.userData, password, confirmPassword };

        const data = { ...dataToRegister, orders: orderDa.id };
        // const data = { ...orderData.userData, orders: orderData.id };
        dispatch(registerWithOrder(data));
      }
      return orderDa;
    } catch (error) {
      dispatch(ordersContainerToggle(true));
      console.log(error);
      return rejectWithValue(error.response.data);
    }
  }
);

// 2. STORE PENDING PAYPAL ORDER

export const storePendingPaymentOrder = createAsyncThunk(
  "/order/store_pending",
  async ({ orderData, userAction, currUser }, thunkApi) => {
    const { rejectWithValue, dispatch } = thunkApi;

    try {
      const response = await axios.post(
        `${import.meta.env.VITE_API_URL}/order/place_order`,
        orderData
      );
      console.log("response", response);
      let orderDa = response.data;
      if (response.status === 200) {
        if (currUser) {
          // dispatch(paypalOrderStoreSuccess(res.data));

          dispatch(updateUserOrders({ newOrder: response.data }));
        } else {
          axios.defaults.headers.common.Authorization = `Bearer ${orderDa.token}`;
          localStorage.setItem("token", orderDa.token);
          // const decoded = jwtDecode(orderDa.token);

          orderDa = orderDa.order;
        }
        return orderDa;
      }
    } catch (error) {
      console.log("error", error);
      return rejectWithValue(error.response.data);
    }
  }
);

// 3. UPDATE PAYMENT STATUS

export const updatePaymentStatus = createAsyncThunk(
  "/order/payment_status",
  async ({ status, payment, orderId, currUser }, thunkApi) => {
    const { rejectWithValue, dispatch } = thunkApi;

    const orderData = { payment, orderId, status };
    try {
      const response = await axios.post(
        `${import.meta.env.VITE_API_URL}/order/payment_status`,
        orderData
      );
      const responseData = response.data;
      if (status === "payed") {
        dispatch(resetCart(false));
        dispatch(resetVoucher());
        dispatch(toggleNewOrder(true));
        dispatch(ordersContainerToggle(true));
        dispatch(setCartCheckout(false));

        return { responseData, payment, status, orderId, currUser };
      } else {
        return { responseData, payment, status, orderId, currUser };
      }
    } catch (error) {
      console.log("error", error);

      dispatch(
        addNotification({
          key: 1,
          message: error.response.data,
          type: "error",
          persist: true,
        })
      );
      return rejectWithValue(error.response.data);
    }
  }
);

// 4. UPDATE PAYMENT STATUS STRIPE

export const updatePaymentStatusStripe = createAsyncThunk(
  "/stripe/payment_status",
  async ({ status, payment, orderId, currUser }, thunkApi) => {
    const { rejectWithValue, dispatch } = thunkApi;

    const orderData = { payment, orderId, status };
    try {
      const response = await axios.post(
        `${import.meta.env.VITE_API_URL}/stripe/payment_status`,
        orderData
      );
      const responseData = response.data;
      console.log("res.data", response.data);
      if (status === "payed") {
        dispatch(resetCart(false));
        dispatch(resetVoucher());
        // dispatch(setCurrOrder());
        dispatch(toggleNewOrder(true));
        dispatch(ordersContainerToggle(true));
        dispatch(setCartCheckout(false));

        return { responseData, payment, status, orderId, currUser, updatedOrder: response.data };
      } else {
        return { responseData, payment, status, orderId, currUser, updatedOrder: response.data };
      }
    } catch (error) {
      console.log("error", error);

      dispatch(
        addNotification({
          key: 1,
          message: error.response.data,
          type: "error",
          persist: true,
        })
      );
      return rejectWithValue(error.response.data);
    }
  }
);

// 5. GET CONFIGURED PAYMENT METHODS STRIPE

export const getPaymentsMethodsStripe = createAsyncThunk(
  "/stripe/get-payment-configuration",
  async (_, thunkApi) => {
    const { rejectWithValue, dispatch } = thunkApi;

    try {
      const response = await axios.get(
        `${import.meta.env.VITE_API_URL}/stripe/get-payment-configuration`
      );
      const responseData = response.data;
      // console.log('responseData', responseData)
      if (response?.status === 200) {
        dispatch(setPaymentMethodUrls(responseData?.methodUrl));
      }
    } catch (error) {
      console.log("error", error);

      dispatch(
        addNotification({
          key: 1,
          message: error.response.data,
          type: "error",
          persist: true,
        })
      );
      return rejectWithValue(error.response.data);
    }
  }
);

export const OrderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    setStripeSession(state, action) {
      state.stripeSession = action.payload;
    },
    setPaymentMethodUrls(state, action) {
      state.paymentMethodUrls = action.payload;
    },
    setSelectedCategoryOfferTimes(state, action) {
      state.selectedCategoryOfferTimes = action.payload;
    },
    setIsOfferTimeItemSelected(state, action) {
      state.isOfferTimeItemSelected = action.payload;
    },
    toggleStripeButtonLoading(state, action) {
      state.stripeButtonLoading = action.payload;
    },
    removePError(state, action) {
      state.pError = null;
    },
    // not used
    placeOrderLocal(state, action) {
      return {
        ...state,
        currOrder: action.payload.order,
        currOrdersTrackers: [
          ...state.currOrdersTrackers,
          {
            orderId: action.payload.order.id,
            emailSent: false,
            orderSent: false,
            activeStep: 0,
            rejected: false,
            delayed: false,
            reason: "",
            duration: null,
          },
        ],
        localOrders:
          state.localOrders.length !== 0
            ? [...state.localOrders, action.payload.order]
            : [action.payload.order],
      };
    },
    selectArea(state, action) {
      return {
        ...state,
        selectedArea: action.payload,
      };
    },
    selectAreaToggle(state, action) {
      return {
        ...state,
        showAreaSelection: action.payload,
      };
    },
    setOrderTime(state, action) {
      return {
        ...state,
        delayedTime: action.payload.time,
        delayedOrder: action.payload.delayed,
      };
    },
    toggleNewOrder(state, action) {
      return {
        ...state,
        newOrderPlaced: action.payload,
      };
    },
    ordersContainerToggle(state, action) {
      return {
        ...state,
        ordersContainerVisible: action.payload,
      };
    },
    setSocketId(state, action) {
      return {
        ...state,
        socketId: action.payload,
      };
    },
    setEmailSent(state, action) {
      return {
        ...state,
        currOrdersTrackers: state.currOrdersTrackers.map((t) => {
          if (t.orderId === state.currOrder.id) {
            return { ...t, emailSent: action.payload };
          } else return t;
        }),
      };
    },
    setOrderSent(state, action) {
      return {
        ...state,
        currOrdersTrackers: state.currOrdersTrackers.map((t) => {
          if (t.orderId === state.currOrder.id) {
            return { ...t, orderSent: action.payload };
          } else return t;
        }),
      };
    },
    resetCurrOrder(state, action) {
      return {
        ...state,
        ordersContainerVisible: false,
        orderSent: false,
        activeStep: 0,
        rejected: false,
        delayed: false,
        reason: "",
        duration: null,
        currOrder: null,
        emailSent: false,
        error: null,
        delayedOrder: false,
      };
    },
    setTrackerActiveStep(state, action) {
      return {
        ...state,
        currOrdersTrackers: state.currOrdersTrackers.map((t) => {
          if (t.orderId === action.payload.id) {
            return { ...t, activeStep: action.payload.count };
          } else return t;
        }),
      };
    },
    setTrackerRejected(state, action) {
      return {
        ...state,
        currOrdersTrackers: state.currOrdersTrackers.map((t) => {
          if (t.orderId === action.payload.id) {
            return { ...t, rejected: action.payload.status };
          } else return t;
        }),
      };
    },
    setTrackerDelayed(state, action) {
      return {
        ...state,
        currOrdersTrackers: state.currOrdersTrackers.map((t) => {
          if (t.orderId === action.payload.id) {
            return { ...t, delayed: action.payload.status };
          } else return t;
        }),
      };
    },
    setTrackerReason(state, action) {
      return {
        ...state,
        currOrdersTrackers: state.currOrdersTrackers.map((t) => {
          if (t.orderId === action.payload.id) {
            return { ...t, reason: action.payload.reason };
          } else return t;
        }),
      };
    },

    setTrackerDuration(state, action) {
      return {
        ...state,
        currOrdersTrackers: state.currOrdersTrackers.map((t) => {
          if (t.orderId === action.payload.id) {
            return { ...t, duration: action.payload.duration };
          } else return t;
        }),
        currOrder: { ...state.currOrder, duration: action.payload.duration },
      };
    },
    setCurrOrder(state, action) {
      return {
        ...state,
        currOrder: action.payload.order,
      };
    },
    setLocalOrders(state, action) {
      return { ...state, localOrders: action.payload };
    },
    setIncompleteOrdersCount(state, action) {
      return { ...state, incompleteOrdersCount: action.payload.incOrders };
    },
    placeOrderStart(state, action) {
      return { ...state, loading: true };
    },

    // local reducer for updatePaymentStatusUnpaid (thunk)
    updatePaymentStatusUnpaid(state, action) {
      return {
        ...state,
        currOrderStatus: action.payload.status,
        currOrder: {
          ...state.currOrder,
          paypalData: action.payload.payment,
          paymentStatus: action.payload.status,
        },
        loading: false,
        error: null,
      };
    },
    openEighteenPlusItemDialogOpen(state, action) {
      state.isEighteenPlusItemDialogOpen = action.payload;
    },
    setEighteenPlusItem(state, action) {
      state.eighteenPlusItem = action.payload;
    },
    setCartCheckout(state, action) {
      console.log("action.payload", action.payload);
      state.cartCheckout = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(placeOrder.pending, (state, action) => {
        return { ...state, loading: true };
      })
      .addCase(placeOrder.fulfilled, (state, action) => {
        return {
          ...state,
          currOrder: action.payload,
          currOrderStatus: "placed",
          currOrdersTrackers: [
            ...state.currOrdersTrackers,
            {
              orderId: action.payload.id,
              emailSent: false,
              orderSent: false,
              activeStep: 0,
              rejected: false,
              delayed: false,
              reason: "",
              duration: null,
            },
          ],
          // state.localOrders !== []
          localOrders: action.meta.currUser
            ? []
            : state.localOrders?.length !== 0
            ? [...state.localOrders, action.payload]
            : [action.payload],
          delayedOrder: initialState.delayedOrder,
          delayedTime: initialState.delayedTime,
          loading: false,
          error: null,
        };
      })
      .addCase(placeOrder.rejected, (state, action) => {
        console.log("placeOrder rejected", action);
        console.log("placeOrder rejected payload", action.payload);
        return { ...state, currOrder: null, loading: false, error: action.payload?.error };
      });
    builder
      .addCase(storePendingPaymentOrder.pending, (state, action) => {
        return {
          ...state,
          pLoading: true,
          pError: null,
        };
      })
      .addCase(storePendingPaymentOrder.fulfilled, (state, action) => {
        return {
          ...state,
          currOrder: action.payload,
          currOrderStatus: "payment pending",
          pLoading: false,
          pError: null,
        };
      })
      .addCase(storePendingPaymentOrder.rejected, (state, action) => {
        return {
          ...state,
          currOrder: null,
          currOrderStatus: "storing failed",
          pError: action.payload?.error,
          pLoading: false,
        };
      });
    builder
      .addCase(updatePaymentStatus.pending, (state, action) => {
        return { ...state, loading: true };
      })
      .addCase(updatePaymentStatus.fulfilled, (state, action) => {
        const currOrderpaymentStatus = action.payload.status;
        const prevDelayedStatus = state.delayedOrder;
        const prevDelayedValue = state.delayedTime;

        return {
          // responseData, payment, status, orderId, currUser
          ...state,
          currOrderStatus: action.payload.status,
          currOrder: {
            ...state.currOrder,
            paypalData: action.payload.payment,
            paymentStatus: action.payload.status,
          },
          currOrdersTrackers: [
            ...state.currOrdersTrackers,
            {
              orderId: action.payload.orderId,
              emailSent: false,
              orderSent: false,
              activeStep: 0,
              rejected: false,
              delayed: false,
              reason: "",
              duration: null,
            },
          ],
          localOrders: action.payload.currUser
            ? []
            : state.localOrders.length !== 0
            ? [
                ...state.localOrders,
                {
                  ...state.currOrder,
                  paypalData: action.payload.payment,
                  paymentStatus: action.payload.status,
                },
              ]
            : [
                {
                  ...state.currOrder,
                  paypalData: action.payload.payment,
                  paymentStatus: action.payload.status,
                },
              ],
          delayedOrder:
            currOrderpaymentStatus === "paid" || currOrderpaymentStatus === "payed"
              ? initialState.delayedOrder
              : prevDelayedStatus,
          delayedTime:
            currOrderpaymentStatus === "paid" || currOrderpaymentStatus === "payed"
              ? initialState.delayedTime
              : prevDelayedValue,
          loading: false,
          error: null,
        };
      })
      .addCase(updatePaymentStatus.rejected, (state, action) => {
        return { ...state, loading: false, error: action.payload.error };
      });
    builder
      .addCase(updatePaymentStatusStripe.pending, (state, action) => {
        // window.location.pathname = "/";
        return { ...state, loading: true };
      })
      .addCase(updatePaymentStatusStripe.fulfilled, (state, action) => {
        // window.location.pathname = "/";
        const currOrderpaymentStatus = action.payload.status;
        const prevDelayedStatus = state.delayedOrder;
        const prevDelayedValue = state.delayedTime;
        return {
          // responseData, payment, status, orderId, currUser
          ...state,
          currOrderStatus: action.payload.status,
          currOrder: {
            // ...state.currOrder,
            ...action.payload.updatedOrder,
            // stripeData: action.payload.payment,
            stripePaymentId: action.payload.payment,
            paymentStatus: action.payload.status,
          },
          currOrdersTrackers: [
            ...state.currOrdersTrackers,
            {
              orderId: parseInt(action.payload.orderId),
              emailSent: false,
              orderSent: false,
              activeStep: 0,
              rejected: false,
              delayed: false,
              reason: "",
              duration: null,
            },
          ],
          localOrders: action.payload.currUser
            ? []
            : state.localOrders.length !== 0
            ? [
                ...state.localOrders,
                {
                  ...state.currOrder,
                  paypalData: action.payload.payment,
                  paymentStatus: action.payload.status,
                },
              ]
            : [
                {
                  ...state.currOrder,
                  paypalData: action.payload.payment,
                  paymentStatus: action.payload.status,
                },
              ],
          delayedOrder:
            currOrderpaymentStatus === "paid" || currOrderpaymentStatus === "payed"
              ? initialState.delayedOrder
              : prevDelayedStatus,
          delayedTime:
            currOrderpaymentStatus === "paid" || currOrderpaymentStatus === "payed"
              ? initialState.delayedTime
              : prevDelayedValue,
          // delayedTime: initialState.delayedTime,
          loading: false,
          error: null,
        };
      })
      .addCase(updatePaymentStatusStripe.rejected, (state, action) => {
        return { ...state, loading: false, error: action.payload.error };
      });
  },

  //   // Update Payment Status
  //   [updatePaymentStatus.pending]: (state, action) => {
  //     return { ...state, loading: true };
  //   },
  //   [updatePaymentStatus.fulfilled]: (state, action) => {
  //     return {
  //       ...state,
  //       currOrderStatus: action.payload.status,
  //       currOrder: {
  //         ...state.currOrder,
  //         paypalData: action.payload.payment,
  //         paymentStatus: action.payload.status,
  //       },
  //       currOrdersTrackers: [
  //         ...state.currOrdersTrackers,
  //         {
  //           orderId: action.payload.orderId,
  //           emailSent: false,
  //           orderSent: false,
  //           activeStep: 0,
  //           rejected: false,
  //           delayed: false,
  //           reason: "",
  //           duration: null,
  //         },
  //       ],
  //       localOrders: action.payload.currUser
  //         ? []
  //         : state.localOrders !== []
  //         ? [
  //             ...state.localOrders,
  //             {
  //               ...state.currOrder,
  //               paypalData: action.payload.payment,
  //               paymentStatus: action.payload.status,
  //             },
  //           ]
  //         : [
  //             {
  //               ...state.currOrder,
  //               paypalData: action.payload.payment,
  //               paymentStatus: action.payload.status,
  //             },
  //           ],
  //       delayedOrder: initialState.delayedOrder,
  //       delayedTime: initialState.delayedTime,
  //       loading: false,
  //       error: null,
  //     };
  //   },
  //   [updatePaymentStatus.rejected]: (state, action) => {
  //     return { ...state, loading: false, error: action.payload.error };
  //   },
  // },
});

export const {
  setPaymentMethodUrls,
  setCartCheckout,
  setEighteenPlusItem,
  openEighteenPlusItemDialogOpen,
  placeOrderLocal,
  selectArea,
  selectAreaToggle,
  setOrderTime,
  toggleNewOrder,
  ordersContainerToggle,
  setSocketId,
  setEmailSent,
  setOrderSent,
  resetCurrOrder,
  setTrackerActiveStep,
  setTrackerRejected,
  setTrackerDelayed,
  setTrackerReason,
  setTrackerDuration,
  setCurrOrder,
  setLocalOrders,
  setIncompleteOrdersCount,
  placeOrderStart,
  updatePaymentStatusUnpaid,
  toggleStripeButtonLoading,
  setIsOfferTimeItemSelected,
  removePError,
  setSelectedCategoryOfferTimes,
  setStripeSession,
} = OrderSlice.actions;
