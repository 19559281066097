import React, { useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Button,
  Card,
  CardHeader,
  CardMedia,
  CardContent,
  Avatar,
  IconButton,
  Box,
  useTheme,
} from "@mui/material";

import { ExpandMore } from "@mui/icons-material";

import { enqueueSnackbar } from "notistack";

import "./Item.css";
import FavItem from "./FavItem";
import NotAvailable from "./NotAvailable";
import AllergyInfo from "./AllergyInfo";
import PriceCalculator from "./PriceCalculator";

import { useTranslation } from "react-i18next";

import { FaCartPlus } from "react-icons/fa";
import {
  customizeItem,
  resetCustomize,
  setCurrItemToppingCats,
  setSelectedSizeId,
  updateSizePrice,
} from "../../../ToolKit/Slices/ItemSlice";
import {
  iniMinLimits,
  initToppingLimit,
  resetToppings,
} from "../../../ToolKit/Slices/ToppingSlice";
import { addToCart } from "../../../ToolKit/Slices/CartSlice";
import {
  selectAreaToggle,
  setIsOfferTimeItemSelected,
  setOrderTime,
  setSelectedCategoryOfferTimes,
} from "../../../ToolKit/Slices/OrderSlice";
import { timeDialogToggle, vacationsDialogToggle } from "../../../ToolKit/Slices/ShopSlice";
import ItemDialog from "./ItemDialog";
import ItemSizePrices from "./ItemSizePrices";
import ShowLimitedDesc from "./ShowLimitedDesc";
import ItemOfferDialog from "./ItemOfferDialog";

function ItemNew(props) {
  const {
    item,
    offerItemAvailable,
    category,
    offerMessage,
    orderType,
    categoryTime,
    categoryOfferTimes,
  } = props;

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const theme = useTheme();
  const priceFormat = window.$priceFormater;

  const currItem = useSelector((state) => state.item.currItem);
  const toppingAndItem = useSelector((state) => state.topping.toppingAndItem);
  // const orderType = useSelector((state) => state.cart.orderType);
  const todayTime = useSelector((state) => state.shop.todayTiming);
  const categories = useSelector((state) => state.menu.categories);
  const discounts = useSelector((state) => state.shop.settings.discounts);
  const allergies = useSelector((state) => state.menu.allergies);
  const isOrderPossible = useSelector((state) => state.shop.isOrderPossible);
  const toppingCategories = useSelector((state) => state.topping.toppingCategories);
  const showAreaSelection = useSelector((state) => state.order.showAreaSelection);
  const isShopCurrentlyOpen = useSelector((state) => state.shop.isShopCurrentlyOpen);
  const isOfferTimeItemSelected = useSelector((state) => state.order.isOfferTimeItemSelected);

  // console.log("categoryTime", categoryTime);
  // console.log("isShopCurrentlyOpen?.status", isShopCurrentlyOpen?.status);

  const vacationActiveToday = useSelector((state) => state.shop.vacationActiveToday);
  const sizedPrices = useSelector((state) => state.menu.prices);
  // states

  const [hasSize, setHasSize] = useState(categories?.find((el) => el.id === item.catId)?.hasSize);
  const [chosenInterval, setChosenInterval] = useState("");

  const [hasTopping, setHasTopping] = useState(toppingAndItem?.find((x) => x.itemId === item.id));
  const [itemDialogVisible, setItemDialogVisible] = useState(false);
  const [infoOpen, setInfoOpen] = useState(false);

  const [showAll, setShowAll] = useState(false);
  const [openItemOfferDialog, setOpenItemOfferDialog] = useState(false);

  const [itemSelectedTemp, setItemSelectedTemp] = useState(null);

  useEffect(() => {
    if (orderType !== "" && itemSelectedTemp !== null) {
      if (item.id === itemSelectedTemp.id) {
        AddCart();
        setItemSelectedTemp(null);
      }
    }
  }, [orderType]);

  // const [hasTopping, setItemHasTopping] = useState(undefined);
  // useEffect(() => {
  //   // check if item has toppings
  //   // console.log("toppingAndItem", toppingAndItem);
  //   // if (toppingAndItem !== null) {
  //   const hasTopping = toppingAndItem?.find((x) => x.itemId === item?.id);
  //   console.log("item: ", item?.name, " -> ", item?.id, ",  ", hasTopping);
  //   setItemHasTopping(hasTopping);
  //   // }
  // }, [toppingAndItem, item]);

  const handleClick = (size) => {
    // console.log("hasTopping", hasTopping);
    // is not manually closed

    const isDialog = hasSize && item.price === null ? true : false;
    if (vacationActiveToday && orderType !== "kiosk") {
      dispatch(vacationsDialogToggle(true));
    } else {
      if (item.inStock) {
        if (!(currItem.id === item.id && currItem.size === size.name)) {
          // isOrderPossible ?
          // is today open ?
          // hasTopping

          dispatch(
            customizeItem({
              ...item,
              offerItemAvailable,
              offerMessage,
              itemHasSize: hasSize,
            })
          );

          const currPrice = sizedPrices.find(
            (price) => price.sizeId === size.id && price.itemId === item.id
          );

          const customCurrItem = {
            ...item,
            offerItemAvailable,
            offerMessage,
            itemHasSize: hasSize,
            size: size.name,
            price: currPrice.price,
          };

          // if (isShopCurrentlyOpen.status && isOrderPossible) {
          if ((todayTime.open && isOrderPossible) || orderType === "kiosk") {
            // check if item has topping
            if (categories?.find((el) => el.id === item.catId)?.type === "offer") {
              if (offerItemAvailable) {
                if (hasTopping) {
                  setItemDialogVisible(isDialog ? true : itemDialogVisible);

                  dispatch(resetToppings());
                } else {
                  dispatch(
                    addToCart({
                      item,
                      toppings: null,
                      discPrice:
                        item.deliveryDisc || item.pickupDisc ? calculatePrice(currPrice) : null,
                    })
                  );
                }
              } else {
                showSnack(
                  `${item.name} ${t("notify.isOnlyAvailableOn")} ${offerMessage}`,
                  "info",
                  true
                );
              }
            } else {
              if (hasTopping !== undefined) {
                setItemDialogVisible(isDialog ? true : itemDialogVisible);

                dispatch(resetToppings());
              } else {
                dispatch(
                  addToCart({
                    item: customCurrItem,
                    toppings: null,
                    discPrice:
                      item.deliveryDisc || item.pickupDisc ? calculatePrice(currPrice) : null,
                  })
                );

                showSnack(`${item.name} ${t("notify.addedToCart")} `, "success", false);
              }
            }
            itemSizeClickHandler(item.id, size.id, size.name);
          } else {
            dispatch(timeDialogToggle(true));
          }
        }
        // else {
        //   if (isDialog) {
        //     dispatch(resetCustomize());
        //   }
        // }
      } else {
        showSnack(`${item.name} ${t("notify.isNotAvailable")}`, "warning", false);
      }
    }
  };

  const itemSizeClickHandler = (itemId, sizeId, sizeName) => {
    const currPrice = sizedPrices.find(
      (price) => price.sizeId === sizeId && price.itemId === itemId
    );

    dispatch(setSelectedSizeId(sizeId));

    dispatch(updateSizePrice({ itemSize: sizeName, itemPrice: currPrice.price }));
  };

  const calculatePrice = () => {
    const { item } = props;

    let orignalPrice = item.price;
    let discPrice = null;

    if (!item.isDiscount) {
      if (discounts.deliveryDiscountActive && orderType === "delivery" && item.deliveryDisc) {
        discPrice = orignalPrice - orignalPrice * (discounts.deliveryDiscount / 100);
      } else if (discounts.pickupDiscountActive && orderType === "pickup" && item.pickupDisc) {
        discPrice = orignalPrice - orignalPrice * (discounts.pickupDiscount / 100);
      } else {
        discPrice = null;
      }
    } else {
      if (orderType === "delivery") {
        if (item.discounts.deliveryType === "percentage") {
          discPrice = orignalPrice - (orignalPrice / 100) * item.discounts.delivery;
        } else {
          discPrice = orignalPrice - item.discounts.delivery;
        }
      } else {
        if (item.discounts.pickupType === "percentage") {
          discPrice = orignalPrice - (orignalPrice / 100) * item.discounts.pickup;
        } else {
          discPrice = orignalPrice - item.discounts.pickup;
        }
      }
    }
    return discPrice;
  };

  const showSnack = (message, type, persist) => {
    enqueueSnackbar(message, {
      variant: type,
      persist: persist,
    });
  };

  const assignToppings = (item) => {
    const tCatItems = toppingAndItem.filter((tItem) => tItem.itemId === item.id);

    let tCategories = toppingCategories.filter((tCat) =>
      tCatItems.some((tCatItem) => tCat.tCatId === tCatItem.tCatId)
    );

    dispatch(setCurrItemToppingCats(tCategories));

    const catLimits = tCategories.map((tCat) => {
      return (({ tCatId, max, free, min }) => ({ tCatId, max, free, min }))(tCat);
    });
    const minLimCats = catLimits.filter((lim) => lim.min > 0);

    dispatch(initToppingLimit(catLimits));
    dispatch(iniMinLimits(minLimCats));

    dispatch(setCurrItemToppingCats(tCategories));
  };

  const openItemDetails = () => {
    console.log("hello");
    if (toppingAndItem?.find((x) => x.itemId === item.id)) {
      assignToppings(item);
      dispatch(
        customizeItem({
          ...item,
          offerItemAvailable,
          offerMessage,
          // itemHasSize: categories,
          itemHasSize: hasSize,
        })
      );
      dispatch(resetToppings());
    } else {
      dispatch(
        addToCart({
          item,
          toppings: null,
          discPrice: calculatePrice(),
        })
      );
      showSnack(`${item.name} ${t("notify.addedToCart")}`, "success", false);
    }
    setItemDialogVisible(true);
  };

  const AddCart = () => {
    console.log("i am runnning");
    if (vacationActiveToday && orderType !== "kiosk") {
      dispatch(vacationsDialogToggle(true));
    } else {
      if (currItem?.id !== item.id) {
        if ((todayTime.open && isOrderPossible) || orderType === "kiosk") {
          if (orderType !== "") {
            if (categories?.find((el) => el.id === item.catId)?.type === "offer") {
              if (offerItemAvailable) {
                if (
                  categoryTime === "before" &&
                  ((!isShopCurrentlyOpen?.status && isShopCurrentlyOpen?.message === "less") ||
                    orderType === "kiosk")
                ) {
                  if (!isOfferTimeItemSelected) {
                    setOpenItemOfferDialog(true);
                  } else {
                    openItemDetails();
                  }
                } else if (
                  categoryTime === "before" &&
                  (isShopCurrentlyOpen?.status || orderType === "kiosk")
                ) {
                  if (!isOfferTimeItemSelected) {
                    console.log("helllo");
                    setOpenItemOfferDialog(true);
                  } else {
                    openItemDetails();
                  }
                } else if (
                  categoryTime === "between" &&
                  (isShopCurrentlyOpen?.status || orderType === "kiosk")
                ) {
                  // setOpenItemOfferDialog(false);
                  dispatch(setIsOfferTimeItemSelected(true));
                  dispatch(setSelectedCategoryOfferTimes(categoryOfferTimes));
                  openItemDetails();
                } else if (categoryTime === "after") {
                  showSnack(
                    `${item.name} ${t("notify.isOnlyAvailableOn")} ${offerMessage}`,
                    "info",
                    true
                  );
                } else if (categoryTime === "") {
                  openItemDetails();
                  // if (toppingAndItem?.find((x) => x.itemId === item.id)) {
                  //   assignToppings(item);
                  //   dispatch(
                  //     customizeItem({
                  //       ...item,
                  //       offerItemAvailable,
                  //       offerMessage,
                  //       // itemHasSize: categories,
                  //       itemHasSize: hasSize,
                  //     })
                  //   );
                  //   dispatch(resetToppings());
                  // } else {
                  //   dispatch(
                  //     addToCart({
                  //       item,
                  //       toppings: null,
                  //       discPrice: calculatePrice(),
                  //     })
                  //   );
                  //   showSnack(`${item.name} ${t("notify.addedToCart")}`, "success", false);
                  // }
                }
              } else {
                showSnack(
                  `${item.name} ${t("notify.isOnlyAvailableOn")} ${offerMessage}`,
                  "info",
                  true
                );
              }
            } else {
              console.log("in else?");

              if (hasTopping !== undefined) {
                assignToppings(item);
                dispatch(
                  customizeItem({
                    ...item,
                    offerItemAvailable,

                    offerMessage,
                    // itemHasSize: categories,
                    itemHasSize: hasSize,
                    // itemHasSize: true,
                  })
                );
                dispatch(resetToppings());
              } else {
                dispatch(
                  addToCart({
                    item,
                    toppings: null,
                    discPrice: calculatePrice(),
                  })
                );
                showSnack(`${item.name} ${t("notify.addedToCart")}`, "success", false);
              }
              //......
              // this is for emergency
              //.......
              // if (hasTopping === undefined || hasTopping === null) {
              //   dispatch(addToCart({ item, toppings: null, discPrice: calculatePrice() }));
              //   showSnack(`${item.name} ${t("notify.addedToCart")}`, "success", false);
              // } else {
              //   dispatch(
              //     customizeItem({
              //       ...item,
              //       offerItemAvailable,

              //       offerMessage,
              //       itemHasSize: categories,
              //     })
              //   );
              //   dispatch(resetToppings());
              // }
            }
          } else {
            dispatch(selectAreaToggle(true));
            // setItemSelectedTemp(size);
          }
        } else {
          dispatch(timeDialogToggle(true));
        }
      } else {
        console.log("end else");
        dispatch(resetCustomize());
        dispatch(resetToppings());
      }
    }

    if (orderType !== "" && ((todayTime.open && isOrderPossible) || orderType === "kiosk")) {
      if (categories?.find((el) => el.id === item.catId)?.type === "offer") {
        if (offerItemAvailable) {
          if (
            categoryTime === "before" &&
            ((!isShopCurrentlyOpen?.status && isShopCurrentlyOpen?.message === "less") ||
              orderType === "kiosk")
          ) {
            if (!isOfferTimeItemSelected) {
              setOpenItemOfferDialog(true);
            } else {
              setItemDialogVisible(true);
            }
            // setOpenItemOfferDialog(true);
          } else if (
            categoryTime === "between" &&
            (isShopCurrentlyOpen?.status || orderType === "kiosk")
          ) {
            dispatch(setIsOfferTimeItemSelected(true));
            dispatch(setSelectedCategoryOfferTimes(categoryOfferTimes));

            setItemDialogVisible(true);
          } else if (categoryTime === "after") {
            showSnack(
              `${item.name} ${t("notify.isOnlyAvailableOn")} ${offerMessage}`,
              "info",
              true
            );
          } else if (categoryTime === "") {
            setItemDialogVisible(true);
          }
        }
      } else if (categories?.find((el) => el.id === item.catId)?.type !== "offer") {
        setItemDialogVisible(true);
      }
    }
  };

  const handleProceed = () => {
    dispatch(setOrderTime({ time: chosenInterval, delayed: true }));
    dispatch(setIsOfferTimeItemSelected(true));
    dispatch(setSelectedCategoryOfferTimes(categoryOfferTimes));
    setClose();

    // for single size

    if (toppingAndItem?.find((x) => x.itemId === item.id)) {
      assignToppings(item);
      dispatch(
        customizeItem({
          ...item,
          offerItemAvailable,
          offerMessage,
          // itemHasSize: categories,
          itemHasSize: hasSize,
        })
      );
      dispatch(resetToppings());
    } else {
      dispatch(
        addToCart({
          item,
          toppings: null,

          discPrice: calculatePrice(),
          // discPrice: calculatePrice(),
        })
      );
      showSnack(`${item.name} ${t("notify.addedToCart")}`, "success", false);
    }

    setItemDialogVisible(true);
  };
  let itemAllergies = [];

  if (item.allergies) {
    item.allergies.forEach((a) => {
      itemAllergies.push(allergies.find((al) => al.id === a));
    });
  }

  useEffect(() => {
    if (!showAreaSelection) {
      setItemSelectedTemp(0);
    }
  }, [showAreaSelection]);

  const setClose = () => {
    setOpenItemOfferDialog(false);
  };

  // const singleItemClick = useCallback(() => {
  //   console.log("Hello");
  //   if (orderType !== "") {
  //     AddCart();
  //   } else {
  //     dispatch(selectAreaToggle(true));
  //     setItemSelectedTemp(item);
  //   }
  // }, [orderType, item]);

  const SizePriceInfo = useMemo(() => {
    return (
      <>
        {hasSize && item.price === null ? (
          <ItemSizePrices
            from="item new"
            hasSize={hasSize}
            offerItemAvailable={offerItemAvailable}
            offerMessage={offerMessage}
            item={item}
            setItemDialogVisible={setItemDialogVisible}
            // setItemDialogVisible={handleDialogCallback}
            itemDialogVisible={itemDialogVisible}
            isDialog={true}
            categoryTime={categoryTime}
            setOpenItemOfferDialog={setOpenItemOfferDialog}
            categoryOfferTimes={categoryOfferTimes}
          />
        ) : (
          <div style={{ width: "100%" }} className="SizePrice">
            {/* single size button (party pizza , MITTA.... , MONTA...) */}
            <Button
              disabled={item.inStock && props.category !== undefined ? false : true}
              variant={hasTopping ? "outlined" : "contained"}
              color={hasTopping ? "primary" : "secondary"}
              size="medium"
              // onClick={AddCart}
              // onClick={singleItemClick}
              onClick={() => {
                if (orderType !== "") {
                  AddCart();
                } else {
                  dispatch(selectAreaToggle(true));
                  setItemSelectedTemp(item);
                }
              }}
            >
              {hasTopping ? null : <FaCartPlus style={{ paddingRight: 10, fontSize: 25 }} />}
              <PriceCalculator
                item={item}
                price={item.price}
                toppingAmount={0}
                priceFormat={priceFormat}
              />
            </Button>
          </div>
        )}
      </>
    );
  }, [orderType, isOfferTimeItemSelected]);

  // if (hasSize && item.price === null) {
  //   return (
  //     <ItemSizePrices
  //       from="item new"
  //       hasSize={hasSize}
  //       offerItemAvailable={offerItemAvailable}
  //       offerMessage={offerMessage}
  //       item={item}
  //       setItemDialogVisible={setItemDialogVisible}
  //       itemDialogVisible={itemDialogVisible}
  //       isDialog={true}
  //     />
  //   );
  // } else {
  //   return (
  //     <div style={{ width: "100%" }} className="SizePrice">
  //       {/* single size button (party pizza , MITTA.... , MONTA...) */}
  //       <Button
  //         disabled={item.inStock ? false : true}
  //         variant={hasTopping ? "outlined" : "contained"}
  //         color={hasTopping ? "primary" : "secondary"}
  //         size="medium"
  //         onClick={AddCart}
  //       >
  //         {hasTopping ? null : <FaCartPlus style={{ paddingRight: 10 }} />}
  //         <PriceCalculator
  //           item={item}
  //           price={item.price}
  //           toppingAmount={0}
  //           priceFormat={priceFormat}
  //         />
  //       </Button>
  //     </div>
  //   );
  // }
  const itemOptions = useMemo(() => {
    return (
      <React.Fragment>
        {item?.drinkInfo && item?.drinkInfo?.minAge > 0 && (
          <div className="h-[30px] w-[30px] text-[12px] border border-1  flex justify-center items-center rounded-[50%]">
            {item?.drinkInfo?.minAge}+
          </div>
        )}
        <IconButton aria-label="settings">
          <FavItem item={item} />
        </IconButton>
        {itemAllergies[0] || item?.additionalInfo ? (
          <AllergyInfo
            itemAllergies={itemAllergies}
            infoOpen={infoOpen}
            setInfoOpen={setInfoOpen}
            additionalInfo={item?.additionalInfo}
          />
        ) : null}
        {item.inStock && props.category !== undefined ? null : (
          <IconButton aria-label="settings">
            <NotAvailable />
          </IconButton>
        )}
      </React.Fragment>
    );
  }, [item, infoOpen]);

  // console.log("openItemOfferDialog", openItemOfferDialog);
  // console.log("itemDialogVisible", itemDialogVisible);

  return (
    <>
      <Box
        className="relative Item"
        sx={{
          marginTop: "20px",
          height: item.image === null || item.image === "" ? "fit-content" : "unset",
        }}
      >
        <div className=" text-white text-[12px] ">
          {!item?.isDiscount ? (
            orderType === "delivery" && item.deliveryDisc && discounts?.deliveryDiscountActive ? (
              discounts?.deliveryDiscount !== 0 ? (
                <span className="ribbon-2-main">
                  -{discounts?.deliveryDiscount + "% " + t("item.onDelivery")}
                </span>
              ) : null
            ) : discounts?.pickupDiscount !== 0 ? (
              orderType === "pickup" && item.pickupDisc && discounts?.pickupDiscountActive ? (
                <span className="ribbon-2-main">
                  -{discounts?.pickupDiscount + "% " + t("item.onPickup")}
                </span>
              ) : null
            ) : null
          ) : (
            <span className="ribbon-2-main">
              {orderType === "delivery"
                ? item.discounts.deliveryType === "percentage"
                  ? -item.discounts.delivery + "% " + t("item.onDelivery")
                  : -item.discounts.delivery?.toFixed(2) + " € " + t("item.onDelivery")
                : item.discounts.pickupType === "percentage"
                ? -item.discounts.pickup + "% " + t("item.onPickup")
                : -item.discounts.pickup?.toFixed(2) + " € " + t("item.onPickup")}
            </span>
          )}
        </div>
        <Card
          sx={{
            "& .MuiCardHeader-title": {
              padding: "0px",
            },
          }}
          className=" "
          style={{
            height: item.image === null || item.image === "" ? "fit-content" : "unset",
          }}
        >
          <CardHeader
            sx={{
              p: "10px",
              pb: 0,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
            avatar={
              <Avatar
                sx={{
                  bgcolor: theme.palette.primary.main,
                  color: "primary.contrastText",
                  fontSize: "11px",
                  width: "28px",
                  height: "28px",
                }}
                aria-label="recipe"
                className="avatar"
              >
                {item.articleNo !== null ? item.articleNo : ""}
              </Avatar>
            }
            action={<div className="flex gap-2 items-center">{itemOptions}</div>}
            style={{ textAlign: "left" }}
            title={
              <div>
                <b>{item.name} </b>

                <sub>
                  {itemAllergies
                    ? itemAllergies.map((a, index) => (index ? "," : "") + a?.name)
                    : null}
                </sub>
              </div>
            }
          />

          {/* for setting description right */}
          <CardHeader
            sx={{
              pl: "38px",
              pb: 0,
              pt: 0,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              "& .MuiCardHeader-title": {
                fontSize: "12px",
                fontFamily: "Roboto",
              },
            }}
            avatar={<div></div>}
            action={<div></div>}
            style={{ textAlign: "left" }}
            title={
              <Box className="p-0 relative w-full">
                {/* NEW */}
                {item?.image === null || item?.image === "" ? (
                  <span>{item?.description}</span>
                ) : (
                  item?.description?.length < 65 && (
                    <>
                      <span>{item?.description}</span>
                    </>
                  )
                )}

                {/* ORIGINAL  */}
                {/* {item?.description?.length < 65 ? (
                  <>
                    <span>{item?.description}</span>
                  </>
                ) : (
                  <>{item?.image === null && item?.description}</>
                )} */}
              </Box>
            }
          />
          <Box
            sx={{
              pb: 0,
              fontSize: "12px",
              borderRadius: "50px",
              position: "relative",
            }}
          >
            {item?.description?.length > 65 && item.image !== null && item.image !== "" ? (
              <ShowLimitedDesc showAll={showAll} setShowAll={setShowAll} item={item} />
            ) : (
              <></>
            )}
          </Box>

          {item?.image ? (
            <CardMedia className={`media `} image={item.image} title={item.name} />
          ) : null}
          <CardContent className="me" style={{ padding: "8px 2px" }}>
            <div className=" w-full  flex items-center justify-center my-1">
              <span className="italic  text-[12px] ">
                {item?.drinkInfo && item?.drinkInfo?.size !== undefined && (
                  <ul className="flex gap-1">
                    {item.drinkInfo.pfand !== 0 &&
                      `inkl. Pfand (${priceFormat.format(item.drinkInfo.pfand)}), `}{" "}
                    {item.drinkInfo.reusable
                      ? "/Einweg"
                      : item.drinkInfo?.nonReusable
                      ? "/Mehrweg"
                      : ""}{" "}
                    {item.drinkInfo.size !== 0
                      ? `${priceFormat.format((item.price / item.drinkInfo.size)?.toFixed(2))}`
                      : "0 €"}
                    /l , {item.drinkInfo?.size?.toFixed(2)?.replace(".", ",")} l
                  </ul>
                )}
              </span>
            </div>
            {(hasSize && item.price === null) || hasTopping ? (
              <div className="ExpandButton">{SizePriceInfo}</div>
            ) : (
              <div>{SizePriceInfo}</div>
            )}
          </CardContent>
        </Card>
      </Box>

      {openItemOfferDialog && (
        <ItemOfferDialog
          itemName={item?.name}
          handleProceed={handleProceed}
          open={openItemOfferDialog}
          setClose={setClose}
          offerMessage={offerMessage}
          categoryOfferTimes={categoryOfferTimes}
          categoryTime={categoryTime}
          setChosenInterval={setChosenInterval}
          chosenInterval={chosenInterval}
        />
      )}

      {itemDialogVisible && hasTopping && (
        <ItemDialog
          handleClick={handleClick}
          open={itemDialogVisible}
          setOpen={setItemDialogVisible}
          item={item}
          hasSize={hasSize}
          offerItemAvailable={offerItemAvailable}
          offerMessage={offerMessage}
        />
      )}
    </>
  );
}

export default ItemNew;
