import React, { useCallback } from "react";

import { Divider } from "@mui/material";
import { Paper } from "@mui/material";

import "./OrderSummary.css";
import OrderItems from "./OrderItems";
import OrderTotals from "./OrderTotals";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Payments, Store } from "@mui/icons-material";
import PCMPayment from "../../UI/SVGIcons/PCMPayment";
import OnlinePayment from "../../UI/SVGIcons/OnlinePayment";

function OrderPaper(props) {
  const { currOrder, currOrderItems, shop, discounts, userDiscount, shopLogo, isEmail } = props;
  const { t } = useTranslation();

  // console.log("currOrder", currOrder);

  const priceFormat = window.$priceFormater;
  const orderTimestamp = new Date(currOrder.orderTimestamp).toLocaleString("de-DE", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  });

  const paymentType = useCallback(
    (method, paymentInfo) => {
      if (method === "Cash on Delivery") return <Payments color="success" fontSize="large" />;
      if (method === "Paypal")
        return (
          <img
            src={`https://firebasestorage.googleapis.com/v0/b/react-pizza-shared.appspot.com/o/common%2Fstripe%2Fpaypal.svg?alt=media`}
          />
        );
      if (method === "EC")
        return (
          <img
            className="w-[50px]"
            src="https://firebasestorage.googleapis.com/v0/b/react-pizza-shared.appspot.com/o/common%2FEC-Bold.png?alt=media&token=251558f4-77dc-49f6-a35f-ed6238bdf571"
          />
        );
      if (method === "Stripe")
        return (
          <>
            {paymentInfo ? (
              <img width={45} height={30} src={`${paymentInfo?.stripePaymentMethodImage}`} />
            ) : (
              <OnlinePayment color={"#0000"} width={40} height={50} />
            )}
          </>
        );
    },
    [currOrder?.paymentInfo]
  );

  return (
    <Paper elevation={3}>
      <div
        style={{
          textAlign: "center",
          padding: "20px",
          maxWidth: "500px",
          margin: "auto",
          marginTop: "10px",
          marginBottom: "15px",
          width: "90%",
          border:
            props.themeMode !== undefined && props.themeMode === "dark"
              ? "0px solid #aaa"
              : "1px solid #ccc",
        }}
      >
        <div
          style={{
            display: "inline-flex",
            width: "100%",
            // justifyContent: "space-between",
            // alignItems: "baseline",
          }}
        >
          <div
            style={{
              width: "25%",
              marginTop: "25px",
            }}
          >
            {/* <IconContext.Provider value={{ size: "2em" }}> */}
            {/* OrderType */}
            <div>
              {currOrder.orderType === "kiosk" ? (
                <Store fontSize="large" />
              ) : currOrder.orderType === "delivery" ? (
                <img
                  style={{ margin: "auto" }}
                  src="https://orionid-images.s3.eu-central-1.amazonaws.com/common/order_icons/delivery.png"
                  alt="delivery truck"
                  width="35"
                  height="35"
                />
              ) : (
                <img
                  style={{ margin: "auto" }}
                  src="https://orionid-images.s3.eu-central-1.amazonaws.com/common/order_icons/pickup.png"
                  alt="pickup bag"
                  width="35"
                  height="35"
                />
              )}
              {currOrder.delayedOrder ? (
                <img
                  src="https://orionid-images.s3.eu-central-1.amazonaws.com/common/order_icons/delayed.png"
                  alt="delay clock"
                  width="20"
                  height="20"
                  style={{ marginBottom: "15px", margin: "auto" }}
                />
              ) : null}
            </div>
            {/* </IconContext.Provider> */}
            {currOrder.orderType === "kiosk" ? (
              <p style={{ fontSize: "10px", margin: "2px" }}>Kiosk</p>
            ) : (
              <p style={{ fontSize: "10px", margin: "2px" }}>
                {t(`orderPaper.${currOrder.orderType}`)}
              </p>
            )}
          </div>
          {/* ShopLogo and website name */}
          <div
            style={{ width: "50%" }}
            // style={{ width: "50%", textAlign: "center" }}
            // style={{ width: "50%", display: "flex", flexDirection: "column", alignItems: "center" }}
          >
            {shop.settings.showLogoOnOrder ? (
              <img
                style={{ margin: "auto" }}
                src={shopLogo}
                alt="shop logo"
                // width="100"
              />
            ) : null}

            <h3 style={{ margin: "5px" }}>{shop.website}</h3>
          </div>
          {/* payment type */}
          <div
            style={{
              width: "25%",
              marginTop: "25px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {/* <IconContext.Provider value={{ size: "2em", title: "test" }}> */}
            {isEmail === undefined ? (
              <>
                {paymentType(currOrder?.paymentMethod, currOrder?.userData?.paymentInfo)}
                <p
                  style={{
                    fontSize:
                      isEmail !== undefined && currOrder.paymentMethod === "Stripe"
                        ? "14px"
                        : "10px",
                    margin: "2px",
                    fontWeight:
                      isEmail !== undefined && currOrder.paymentMethod === "Stripe" && "bold",
                  }}
                >
                  {currOrder.paymentMethod === "Cash on Delivery"
                    ? t("orderPaper.cash")
                    : currOrder.paymentMethod === "Stripe"
                    ? currOrder?.userData?.paymentInfo !== undefined
                      ? currOrder?.userData?.paymentInfo?.stripePaymentMethodType
                      : "Stripe"
                    : currOrder.paymentMethod}
                </p>
              </>
            ) : (
              <>
                {currOrder.paymentMethod === "Cash on Delivery" ? (
                  <Payments color="success" fontSize="large" />
                ) : currOrder.paymentMethod === "Paypal" ? (
                  <img
                    src={`https://firebasestorage.googleapis.com/v0/b/react-pizza-shared.appspot.com/o/common%2Fstripe%2Fpaypal.svg?alt=media`}
                  />
                ) : currOrder.paymentMethod === "EC" ? (
                  <img
                    className="w-[50px]"
                    src="https://firebasestorage.googleapis.com/v0/b/react-pizza-shared.appspot.com/o/common%2FEC-Bold.png?alt=media&token=251558f4-77dc-49f6-a35f-ed6238bdf571"
                  />
                ) : // <PCMPayment color={"#0000"} width={35} height={45} />
                currOrder.paymentMethod === "Stripe" ? (
                  <>
                    <p>
                      Zahlungsmethode :{" "}
                      <span
                        style={{
                          fontWeight: "bold",
                          fontSize: "16px",
                        }}
                      >
                        {currOrder?.userData?.paymentInfo?.stripePaymentMethodType}
                      </span>
                    </p>
                  </>
                ) : null}

                {currOrder.paymentMethod !== "Stripe" && (
                  <p
                    style={{
                      fontSize: "10px",
                      margin: "2px",
                    }}
                  >
                    {currOrder.paymentMethod === "Cash on Delivery"
                      ? t("orderPaper.cash")
                      : currOrder.paymentMethod}
                  </p>
                )}
              </>
            )}

            {/* </IconContext.Provider> */}

            {currOrder.paymentMethod === "Paypal" && currOrder?.paypalData ? (
              <p
                className="secondary-section text-[10px]"
                style={{
                  margin: "2px",
                  overflowWrap: "anywhere",
                }}
              >
                {currOrder.paymentMethod === "Paypal"
                  ? currOrder?.paypalData
                    ? currOrder.paypalData.paymentID
                    : currOrder.paymentStatus
                  : currOrder.paymentStatus}
              </p>
            ) : null}
          </div>
        </div>
        <div
          style={{
            display: "inline-flex",
            width: "100%",
            fontSize: "13px",
            color: "#5b5b5b",
            marginTop: "25px",
          }}
        >
          <div style={{ textAlign: "left", padding: "5px", width: "50%" }}>
            <b>{t("orderPaper.customer")}</b>
            <br />
            {currOrder.userData?.name}
            {/* <br />
            {currOrder.userData.email} */}
            <br />
            {currOrder.userData?.phone}
            <br />
            <br />
            {currOrder.orderType !== "pickup" ? (
              <div>
                <b>{t("orderPaper.address")}</b>
                <br />
                {currOrder.userData?.street}
                <br />
                {t("forms.Floor")}: {currOrder.userData?.floor}
                <br />
                {t("forms.Bell Name")}: {currOrder.userData?.bellName}
                <br />
                {currOrder.userData.postalCode}, {currOrder.userData?.city}
                <br />
              </div>
            ) : null}
            <br />
          </div>
          <div style={{ textAlign: "right", padding: "5px", width: "50%" }}>
            <b>
              {/* # {currOrder.serial}-{currOrder.id} */}# {currOrder.serial}
            </b>
            <br />
            {orderTimestamp}
            <br />
            {currOrder.delayedOrder ? (
              <React.Fragment>
                <b>
                  {t("orderPaper.delayedTime")}: {currOrder.delayedTime.slice(0, 5)}
                </b>
                <br />
              </React.Fragment>
            ) : null}
            {currOrder.userData?.device ? (
              currOrder.userData?.device?.Browser?.includes("Chrome WebView") ||
              currOrder.userData?.device?.Browser?.includes("WebKit") ? (
                <span
                  style={{
                    fontSize: "10px",
                    paddingBottom: "10px",
                  }}
                >
                  mit{" "}
                  <img
                    src={`https://orionid-images.s3.eu-central-1.amazonaws.com/common/order_icons/${
                      currOrder.userData?.device?.Browser?.includes("WebKit") ? "apple" : "android"
                    }.png`}
                    alt="androidapp"
                    width="17"
                  />{" "}
                  APP
                </span>
              ) : null
            ) : null}
            <br />
            <b>{t("orderPaper.shopDetails")}</b>
            <br />
            {shop.name}
            <br />
            {shop.street}
            <br />
            {shop.postalCode}, {shop.city}
            {/* <br />
            {shop.email} */}
            <br />
            {shop.phoneNumbers[0]?.phone}
          </div>
        </div>

        <div style={{ textAlign: "left", fontSize: "13px", paddingBottom: "10px" }}>
          <b>{t("orderPaper.message")}:</b> {currOrder.userData?.message}
        </div>
        <Divider />
        <h3>{t("orderPaper.orderSummary")}</h3>
        {Object.keys(currOrderItems).map((item, index) => (
          <div
            key={index}
            style={{
              display: "grid",
              // paddingBottom: "5px",
              marginBottom: "5px",
              width: "95%",
              margin: "auto",
              // borderBottom: "1px solid #ccc",
            }}
          >
            <p
              style={{
                // marginTop: "3px",
                textAlign: "left",
                fontWeight: "bold",
                backgroundColor: "#efefef",
                padding: "2px",
                color: "black",
              }}
            >
              {currOrderItems[item]?.value.name}
            </p>
            {currOrderItems[item]?.items.map((el2, index2) => (
              <OrderItems
                isLast={index2 !== currOrderItems[item].items.length - 1 && index}
                key={index2}
                item={el2}
                discounts={discounts}
                orderType={currOrder.orderType}
                userDiscount={
                  currOrder.userData?.discounts !== undefined ? currOrder.userData?.discounts : null
                }
                // itemDiscount={
                //   el2?.isDiscount ? { isDiscount: el2?.isDiscount, ...el2?.discounts } : null
                // }
                itemDiscount={
                  el2?.isDiscount
                    ? currOrder?.userData?.itemDiscount
                      ? currOrder.userData?.itemDiscount?.find((itemDs) => itemDs.id === el2.id)
                      : null
                    : null
                }
              />
            ))}
          </div>
        ))}

        {currOrder.userData?.discounts?.deliveryDiscountActive &&
        currOrder?.orderType === "delivery" ? (
          <p
            style={{
              textAlign: "right",
              width: "94%",
              margin: "10px auto",
              fontSize: "13px",
              fontStyle: "italic",
              color: "#00bf5e",
            }}
          >
            {t("cart.savingAmount")} <b>{priceFormat.format(currOrder.userData?.amountSaved)}</b>
          </p>
        ) : null}

        {currOrder.userData?.discounts?.pickDiscountActive && currOrder?.orderType === "pickup" ? (
          <p
            style={{
              textAlign: "right",
              width: "94%",
              margin: "10px auto",
              fontSize: "13px",
              fontStyle: "italic",
              color: "#00bf5e",
            }}
          >
            {t("cart.savingAmount")} <b>{priceFormat.format(currOrder.userData?.amountSaved)}</b>
          </p>
        ) : null}
        <OrderTotals order={currOrder} />
        <p
          style={{
            textAlign: "center",
            fontStyle: "italic",
            marginBottom: 0,
            fontSize: 10,
          }}
        >
          <b>{t("orderSummary.notAnInvoice")}</b>
        </p>
      </div>
    </Paper>
  );
}

export default OrderPaper;
