import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const ShowTime = (props) => {
  const { t } = useTranslation();
  const { timings, styleType, fontWeight } = props;
  return (
    <Box
      className={`flex ${
        styleType !== undefined && styleType === "col" ? "flex-col" : "flex-row"
      } items-center gap-1 `}
    >
      {timings?.map((mainArray, mainArrayIndex) => {
        return (
          <Box className="flex  items-center  " key={mainArrayIndex}>
            {/* {styleStype !== "col" && timings?.length > 1 && mainArrayIndex > 0 && <span>&</span>} */}
            {timings?.length > 1 && mainArrayIndex > 0 && styleType !== "col" && (
              <Typography variant="caption">&</Typography>
            )}
            {mainArray.length < 1 ? (
              <p>{t("shopTimings.closed")}</p>
            ) : (
              mainArray.map((time, index) => {
                return (
                  <Box className="flex items-center  " key={index}>
                    <Typography
                      sx={{
                        fontWeight: fontWeight ? fontWeight : "400",
                      }}
                      variant="caption"
                      key={index}
                    >
                      {time.slice(0, 2)}:{time.slice(2, 4)}
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: fontWeight ? fontWeight : "400",
                      }}
                      variant="caption"
                    >
                      {index === 0 ? "-" : null}
                    </Typography>
                  </Box>
                );
              })
            )}
          </Box>
          // <Fragment key={mainArrayIndex}>
          //   {timings?.length > 1 && mainArrayIndex > 0 && <span>&</span>}
          //   {mainArray.length < 1 ? (
          //     <p>{t("shopTimings.closed")}</p>
          //   ) : (
          //     mainArray.map((time, index) => {
          //       return (
          //         <Box className="flex items-center  " key={index}>
          //           <Typography variant="caption" key={index}>
          //             {time.slice(0, 2)}:{time.slice(2, 4)}
          //           </Typography>
          //           <Typography variant="caption">{index === 0 ? "-" : null}</Typography>
          //         </Box>
          //       );
          //     })
          //   )}
          // </Fragment>
        );
      })}
    </Box>
  );
};

export default ShowTime;
