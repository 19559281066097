import { Box, Divider, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const Headers = () => {
  const separateTimings = useSelector(
    (state) => state.shop.settings?.separateTimings
  );
  const isDeliveryDisable = useSelector(
    (state) => state.shop.settings?.isDeliveryDisable
  );
  const { t } = useTranslation();
  const fontSize = ".8rem";
  return (
    <>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns:
            separateTimings && !isDeliveryDisable ? "35% 30% 1fr" : "30% 1fr",
        }}
        className="p-2 items-center "
      >
        <Box
          sx={{
            width: "100%",
          }}
          className={`${
            separateTimings && !isDeliveryDisable ? "text-center" : "text-start"
          }`}
        >
          {/* <Box className="w-[15%]  "> */}
          <Typography fontSize={fontSize} sx={{ fontWeight: "500" }}>
            {t("shopTimings.day")}
          </Typography>
        </Box>
        {/* pickup timings */}
        <Box
          sx={{
            display: !separateTimings && "none",
            width: "100%",
          }}
          className={`${
            separateTimings && !isDeliveryDisable ? "text-center" : isDeliveryDisable ? "text-center" : "text-end"
            // isDeliveryDisable ? "text-center" : "text-end"
          }`}
          // className="w-[25%] md:w-[35%]  md:text-left text-center "
        >
          <Typography fontSize={fontSize} sx={{ fontWeight: "500" }}>
            {t("button.pickup")}
          </Typography>
        </Box>
        {/* delivery & opening timings */}

        {!isDeliveryDisable && (
          <Box
            sx={{
              display: !separateTimings && "none",
              width: "100%",
            }}
            // className="w-[35%]  md:text-left text-center "
            className="flex flex-col text-center "
          >
            <Typography fontSize={fontSize} sx={{ fontWeight: "500" }}>
              {/* {t("Delivery & Pickup")}  */}
              {t("button.delivery")}
            </Typography>
          </Box>
        )}
      </Box>
      <Divider className="w-full" />
    </>
  );
};

export default Headers;
