import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeSnackbar, enqueueSnackbar } from "notistack";
import { FaRegEdit } from "react-icons/fa";
import { RiDeleteBin7Line } from "react-icons/ri";
import AddressForm from "./ProfileForm";
import "./UserData.css";
import { useTranslation } from "react-i18next";

import {
  Close,
  LockOutlined,
  CheckBox,
  AddOutlined,
  Cancel,
  DeleteForever,
  DeleteSweep,
  Person,
  Edit,
  Star,
  Done,
} from "@mui/icons-material";

import {
  Typography,
  Paper,
  Button,
  Divider,
  ButtonGroup,
  IconButton,
  Card,
  Box,
  Avatar,
} from "@mui/material";
import { selectArea } from "../../../ToolKit/Slices/OrderSlice";
import {
  deleteAccount,
  deleteAccountToggle,
  logoutUser,
  removeAddress,
  selectAddress,
  selectAddressLocal,
  setAddingNewAddress,
} from "../../../ToolKit/Slices/UserSlice";
import { setDeliveryCharge } from "../../../ToolKit/Slices/CartSlice";
import clsx from "clsx";
import { sortAreas } from "../../../ToolKit/Slices/utils";

const UserData = (props) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const [userAction, setUserAction] = useState();
  const isAreaSelection = useSelector((state) => state.order.showAreaSelection);
  const userData = useSelector((state) => state.user.currUser);
  const addresses = useSelector((state) => state.user.addresses);
  const orderType = useSelector((state) => state.cart.orderType);
  const profileVisible = useSelector((state) => state.user.profileVisible);
  const isDeleteAccount = useSelector((state) => state.user.isDeleteAccount);
  const currUser = useSelector((state) => state.user.currUser);
  const areas = useSelector((state) => state.shop.deliveryAreas);
  const isDeliveryDisable = useSelector((state) => state.shop.settings?.isDeliveryDisable);

  // const sortingObj = useSelector((state) => state.shop.settings.deliveryAreaSorting);
  // const selectedArea = useSelector((state) => state.order.selectedArea);
  // const [sortedDeliveryAreas, setSortedDeliveryAreas] = useState(null);

  // useEffect(() => {
  //   if (areas.length !== 0) {
  //     const notDefaultAreas = areas.filter((f) => !f.isDefault);
  //     const newAreas = sortAreas(sortingObj, notDefaultAreas);
  //     setSortedDeliveryAreas(newAreas);
  //   }
  // }, [areas]);

  const showActionSnack = (addrId) => {
    const action = (key) => (
      <React.Fragment>
        <Button
          variant="outlined"
          onClick={() => {
            closeSnackbar(key);
          }}
        >
          {t("notify.no")}
        </Button>
        <Button
          variant="outlined"
          onClick={() => {
            dispatch(removeAddress({ userId: userData.id, addrId }));
            // dispatch(selectArea({}));
            closeSnackbar(key);
          }}
          style={{ margin: "5px" }}
        >
          {t("notify.yes")}
        </Button>
      </React.Fragment>
    );
    let message = t("notify.deleteAddressPermanently");
    enqueueSnackbar(message, {
      variant: "warning",
      persist: true,
      action,
    });
  };

  const showSnack = (message, type) => {
    enqueueSnackbar(message, {
      variant: type,
    });
  };

  const areaHandler = (areaId) => {
    const area = areas.filter((area) => area.id === areaId);
    return area[0];
  };

  console.log("userAction", userAction);

  return (
    <div
      className={clsx(" mt-5", {
        "flex flex-col-reverse md:grid md:grid-cols-2":
          userAction === "deleteAccount" || isDeleteAccount
            ? false
            : !isDeliveryDisable
            ? !props.isCart
              ? true
              : false
            : false,
      })}
    >
      <div>
        {orderType === "delivery" || profileVisible || isAreaSelection ? (
          userAction === "deleteAccount" || isDeleteAccount ? (
            <div style={{ padding: "24px" }}>
              <Typography variant="h6" className="title" color="error">
                {t("userProfile.areYouSure")}
              </Typography>
              <p style={{ color: "#f44336" }}>{t("userProfile.thisWillDeleteYourAccount")}</p>
              <div style={{ display: "flex", justifyContent: "space-evenly", width: "100%" }}>
                <Button
                  variant="outlined"
                  size="small"
                  color="primary"
                  style={{ marginTop: "5px", marginBottom: "5px" }}
                  onClick={() => {
                    setUserAction(null);
                    dispatch(deleteAccountToggle(false));
                    if (window.location.pathname !== "/") {
                      window.location.pathname = "/";
                    }
                  }}
                  startIcon={<Cancel />}
                >
                  {t("button.cancel")}
                </Button>
                <Button
                  variant="contained"
                  size="small"
                  color="primary"
                  style={{ marginTop: "5px", marginBottom: "5px" }}
                  onClick={() => {
                    dispatch(deleteAccount(userData.id));
                    // dispatch(logoutUser());
                    // setUserAction(null);
                    // dispatch(deleteAccountToggle(false));
                    // window.location.pathname = "/";
                  }}
                  startIcon={<DeleteForever />}
                >
                  {t("userProfile.yesDeleteAccount")}
                </Button>
              </div>
            </div>
          ) : isDeliveryDisable ? null : (
            <div className="UserDetails">
              <Typography variant="h6" className="title">
                {t("userProfile.deliveryAddress")}
              </Typography>
              {userAction === "add" ? (
                <Paper elevation={7} className="UserAddressForm">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography variant="h6" className="title">
                      {t("userProfile.addNewAddress")}
                    </Typography>
                    <div>
                      <IconButton
                        // style={{ padding: "0 15px 5px 0" }}
                        edge="end"
                        color="inherit"
                        onClick={() => {
                          setUserAction(null);
                          dispatch(setAddingNewAddress(false));
                        }}
                        aria-label="close"
                      >
                        <Close />
                      </IconButton>
                    </div>
                  </div>
                  <AddressForm userAction={userAction} closeHandler={() => setUserAction()} />
                </Paper>
              ) : (
                <div
                  className="UserDetailPaper flex flex-col  justify-center items-center"
                  elevation={0}
                >
                  {addresses?.map((address, index) => (
                    <>
                      <Card
                        onClick={() => {
                          if (!areas.filter((el) => address.userAreaId === el.id)[0]?.active) {
                            showSnack(
                              `${address.city}, ${address.postalCode}. ${t(
                                "Delivery not available at this time"
                              )}`,
                              "error"
                            );
                          } else {
                            dispatch(selectAddressLocal({ addrId: address.addrId }));
                            dispatch(
                              selectAddress({
                                userId: userData.id,
                                addrId: address.addrId,
                              })
                            );
                            dispatch(selectArea(areaHandler(address.userAreaId)));
                            dispatch(
                              setDeliveryCharge(areaHandler(address.userAreaId).deliveryCharges)
                            );
                            showSnack(t("notify.deliveryChargesUpdated"), "info");
                          }
                        }}
                        style={{
                          borderRadius: "10px",
                          padding: "8px 0px",
                          position: "relative",
                          border:
                            address.selected === true
                              ? areas?.filter((el) => address.userAreaId === el.id)[0]?.active
                                ? "1px solid #9ccc65"
                                : "1px solid red"
                              : "0px solid #9ccc65",
                        }}
                        key={index}
                        className="w-full m-2 cursor-pointer flex flex-col "
                      >
                        <Box
                          className=" AddressDetails w-full "
                          // onClick={() => {
                          //   if (!areas.filter((el) => address.userAreaId === el.id)[0]?.active) {
                          //     showSnack(
                          //       `${address.city}, ${address.postalCode}. ${t(
                          //         "Delivery not available at this time"
                          //       )}`,
                          //       "error"
                          //     );
                          //   } else {
                          //     dispatch(selectAddressLocal({ addrId: address.addrId }));
                          //     dispatch(
                          //       selectAddress({ userId: userData.id, addrId: address.addrId })
                          //     );
                          //     dispatch(selectArea(areaHandler(address.userAreaId)));
                          //     dispatch(
                          //       setDeliveryCharge(areaHandler(address.userAreaId).deliveryCharges)
                          //     );
                          //     showSnack(t("notify.deliveryChargesUpdated"), "info");
                          //   }
                          // }}
                        >
                          <li>{address.street}</li>
                          <li>
                            {t("forms.Floor")}: {address.floor}
                          </li>
                          <li>
                            {t("forms.Bell Name")}: {address.bellName}
                          </li>
                          <li>
                            {address.postalCode}, {address.city}
                          </li>
                        </Box>
                        <Box className="absolute  top-[10px] right-[10px] flex justify-center items-center">
                          {address.selected ? (
                            <IconButton
                              size="small"
                              sx={{
                                border: "1px solid #9ccc65",
                                backgroundColor: "green",
                                // padding: "8px",
                              }}
                            >
                              <Done fontSize="small" htmlColor="white" />
                            </IconButton>
                          ) : (
                            <IconButton
                              style={{
                                color: address.selected ? "grey" : "red",
                                border: "1px solid red",
                                padding: "8px",
                              }}
                              size="small"
                              variant="outlined"
                              disabled={address.selected ? true : false}
                              onClick={
                                address.selected && address.active
                                  ? null
                                  : (e) => {
                                      e.stopPropagation();
                                      e.preventDefault();
                                      showActionSnack(address.addrId);
                                    }
                              }
                            >
                              <RiDeleteBin7Line fontSize={"large"} />
                            </IconButton>
                          )}
                        </Box>
                        {/* <div className="AddressOptions">
                          <span className="AddressOp">
                            <ButtonGroup>
                              <Button
                                disabled={address.selected ? true : false}
                                style={{
                                  color: address.selected ? "#6b9b37" : "grey",
                                }}
                                size="small"
                                variant="outlined"
                                onClick={() => {
                                  if (
                                    !areas.filter((el) => address.userAreaId === el.id)[0]?.active
                                  ) {
                                    showSnack(
                                      `${address.city}, ${address.postalCode}. ${t(
                                        "Delivery not available at this time"
                                      )}`,
                                      "error"
                                    );
                                  } else {
                                    dispatch(selectAddressLocal({ addrId: address.addrId }));

                                    dispatch(
                                      selectAddress({ userId: userData.id, addrId: address.addrId })
                                    );
                                    dispatch(selectArea(areaHandler(address?.userAreaId)));
                                    dispatch(
                                      setDeliveryCharge(
                                        areaHandler(address.userAreaId).deliveryCharges
                                      )
                                    );
                                    showSnack(t("notify.deliveryChargesUpdated"), "info");
                                  }
                                }}
                                endIcon={<CheckBox />}
                              >
                                {t("button.default")}
                              </Button>
                            </ButtonGroup>
                          </span>
                        </div> */}
                      </Card>
                    </>
                  ))}
                  <div className="text-center w-full">
                    <Button
                      fullWidth
                      variant="contained"
                      size="small"
                      color="primary"
                      style={{ marginTop: "5px", marginBottom: "5px" }}
                      onClick={() => {
                        setUserAction("add");
                        dispatch(setAddingNewAddress(true));
                      }}
                      startIcon={<AddOutlined />}
                    >
                      {t("userProfile.addNewAddress")}
                    </Button>
                  </div>
                </div>
              )}
            </div>
          )
        ) : null}
      </div>
      {/* <Divider /> */}
      <div className="flex flex-col justify-between">
        <div className="UserDetails">
          {userAction === "edit" ? (
            <Paper elevation={7} className="UserAddressForm">
              <div style={{ display: "flex" }}>
                <Typography variant="h6" className="title">
                  {t("userProfile.updateContact")}
                </Typography>
                <IconButton
                  edge="end"
                  color="inherit"
                  onClick={() => setUserAction(null)}
                  aria-label="close"
                >
                  <Close />
                </IconButton>
              </div>
              <AddressForm userAction={userAction} closeHandler={() => setUserAction()} />
            </Paper>
          ) : userAction === "changePassword" ? (
            <Paper elevation={7} className="UserAddressForm">
              <div className="flex justify-between items-center">
                <Typography variant="h6" className="title">
                  {t("userProfile.changePassword")}
                </Typography>
                <IconButton
                  edge="end"
                  color="inherit"
                  onClick={() => setUserAction(null)}
                  aria-label="close"
                >
                  <Close />
                </IconButton>
              </div>
              <AddressForm userAction={userAction} closeHandler={() => setUserAction()} />
            </Paper>
          ) : (
            <>
              {!isDeleteAccount && (
                <div>
                  {!props.isCart && (
                    <div className="flex flex-col gap-2 justify-center items-center">
                      <Avatar
                        sx={{
                          width: 70,
                          height: 70,
                        }}
                      >
                        <Person fontSize="large" htmlColor="black" />
                      </Avatar>
                      <Typography>{currUser?.name}</Typography>
                    </div>
                  )}
                  <div
                    style={{
                      display: userAction === "deleteAccount" ? "none" : "block",
                    }}
                  >
                    <Typography variant="h6" className="title">
                      {t("userProfile.contactDetails")}
                    </Typography>
                    <div elevation={0} className="UserDetailPaper flex justify-center">
                      <Card
                        sx={{
                          borderRadius: "10px",
                          position: "relative",
                        }}
                        className=" UserDetailDiv flex justify-start items-center p-2 "
                      >
                        <Box className="AddressDetails w-full">
                          <li>{userData?.name}</li>
                          <li>{userData?.email}</li>
                          <li>{userData?.phone}</li>
                        </Box>
                        <Box className="absolute flex justify-center items-center top-[10px] right-[10px]">
                          <IconButton
                            className=" right-0 top-0 "
                            style={{
                              color: "grey",
                              position: "absoute",
                              border: "1px solid grey",
                            }}
                            variant="outlined"
                            size="small"
                            color="inherit"
                            onClick={() => setUserAction("edit")}
                            // startIcon={}
                          >
                            <Edit />
                          </IconButton>
                        </Box>
                        {/* <div className="AddressOptions ">
                          <span className="AddressOp">
                            <Button
                              style={{ color: "grey" }}
                              variant="outlined"
                              size="small"
                              color="inherit"
                              onClick={() => setUserAction("edit")}
                              startIcon={<FaRegEdit />}
                            ></Button>
                          </span>
                        </div> */}
                      </Card>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
        {!props.isCart && userAction !== "deleteAccount" && !isDeleteAccount ? (
          <div className="flex flex-col text-center p-5">
            <Button
              variant="contained"
              size="small"
              color="primary"
              style={{ marginTop: "5px", marginBottom: "5px" }}
              onClick={() => setUserAction("changePassword")}
              startIcon={<LockOutlined style={{ fontSize: "18px" }} />}
            >
              {t("userProfile.changePassword")}
            </Button>
            <Button
              variant="contained"
              size="small"
              color="primary"
              style={{ marginTop: "5px", marginBottom: "5px" }}
              onClick={() => setUserAction("deleteAccount")}
              startIcon={<DeleteSweep style={{ fontSize: "18px" }} />}
            >
              {t("userProfile.deleteAccount")}
            </Button>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default UserData;
